* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
}

html,
body,
address,
blockquote,
div,
dl,
form,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
table,
ul,
dd,
dt,
li,
tbody,
td,
tfoot,
th,
thead,
tr,
button,
del,
ins,
map,
object,
a,
abbr,
acronym,
b,
bdo,
big,
br,
cite,
code,
dfn,
em,
i,
img,
kbd,
q,
samp,
small,
span,
strong,
sub,
sup,
tt,
var,
legend,
fieldset,
p,
aside,
header,
footer,
section,
article {
  border: medium none;
  margin: 0;
  padding: 0;
}

:focus {
  outline: none !important;
}

img,
fieldset {
  border: 0 none;
}

a {
  border: none;
  outline: none;
  text-decoration: none;
}

input[type="submit"] {
  -webkit-appearance: none;
}

img {
  border: none;
  height: auto;
  max-width: 100%;
  outline: none;
  vertical-align: middle;
}

iframe {
  vertical-align: middle;
}

a:hover {
  text-decoration: none;
  color: red;
}

.clear {
  clear: both;
}

ul::after {
  clear: both;
  content: "";
  display: block;
}

ul {
  list-style: none;
  min-height: 150px;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 27px;
  color: #000;
  -webkit-font-smoothing: antialiased;
  font-family: "Wix Madefor Display", sans-serif;
}

.header-left img {
  height: 60px;
}

.upgrade-plan img {
  width: 37%;
  margin-bottom: 44px;
}

.modal-dialog .left-chat-boxes,
.modal-dialog .right-chat-boxes {
  margin: 0 0 15px 0;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  padding: 0 24px 10px;
  border: 1px solid #7269ef;
  border-radius: 22px;
}

.header-left span {
  font-size: 20px;
  color: #000;
  font-weight: 500;
  margin-left: 10px;
}

.main-chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activate-pro-faq {
  display: flex;
  align-items: center;
}

.header-top-left button {
  background: #fff0a3 !important;
  font-weight: 600;
  margin-right: 35px;
}

.header-top-left {
  cursor: pointer;
}

.header-top-left p {
  font-weight: 600;
}

.header-top-right p {
  font-weight: 600;
}

.header-top-right:before {
  content: "";
  position: absolute;
  background: #000;
  width: 2px;
  height: 21px;
  right: -20px;
  bottom: 3px;
}

.header-top-right {
  position: relative;
  margin-right: 35px;
}

.header-right {
  display: flex;
  align-items: center;
}

.email-dropdown {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.email-top-area h6 {
  font-size: 15px;
  font-weight: 400;
  color: #000;
}

.email-top-area p {
  color: #5e5e5e;
  font-size: 13px;
  line-height: 15px;
}

.email-top-area {
  margin-right: 30px;
}

.dropdown-top .dropdown button {
  background: #7269ef;
  border: none;
}

.dropdown-top .dropdown-toggle::after {
  color: #000;
  position: absolute;
  right: 0;
  top: 25px;
  font-size: 19px;
}

.email-dropdown .dropdown-menu.show {
  right: 0 !important;
}

.dropdown-top .dropdown button {
  background: transparent !important;
  border: none;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  font-size: 20px;
}

.email-dropdown .dropdown-top .dropdown button {
  padding: 0 !important;
  margin-right: 15px;
}

.dropdown-top .dropdown button:active:focus {
  background-color: transparent !important;
}

.dropdown-top .btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  background: #7269ef !important;
}

.dropdown-top .btn-check:active+.btn-success:focus,
.btn-check:checked+.btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: none !important;
}

.header {
  padding: 10px 0;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  background: #fff;
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.btn-check:focus+.btn,
.btn:focus {
  box-shadow: none !important;
}

.left-chat-box {
  border-radius: 21px;
}

.chat-history-header span {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}

.add-chat-btn button {
  background: #7269ef !important;
  border: none !important;
  color: #fff !important;
  font-size: 17px;
  width: 100%;
}

.add-chat-btn {
  margin: 20px 0;
}

.chat-history-header {
  padding-top: 5px;
}

.chat-history-area h5 {
  color: #000;
  font-size: 20px;
  font-weight: 500;
}

.chat-history-area {
  padding: 8px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 300px;
  overflow: auto;
  box-shadow: inset 0 0 10px #00000033;
}

.chat-history-area p {
  text-align: center;
  font-size: 16px;
  padding: 10px;
}

.chat-history-area span {
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  border: 1px solid;
  padding: 10px;
  border-radius: 4px;
  padding: 2px 9px 2px 5px;
  width: 100%;
  margin: 2px 0px;
  background-color: #fff;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
}

/* .form-check .form-check-input {
  margin-top: 6px;
  width: fit-content;
  margin-right: 12px;
} */
.chat-history-area h5 {
  font-size: 16px;
}

.chat-bookmark-area h5 {
  color: #000;
  font-size: 20px;
  font-weight: 500;
}

.chat-bookmark-area {
  padding: 11px 4px;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  overflow: auto;
  max-height: 300px;
  min-height: 0px;
}

.chat-bookmark-area p {
  text-align: center;
  font-size: 16px;
  padding: 10px;
}

.chat-bookmark-area span {
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  border: 1px solid;
  padding: 10px;
  border-radius: 4px;
  padding: 2px 9px 2px 5px;
  width: 100%;
  margin: 2px 0px;
  background-color: #fff;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
}

.chat-bookmark-area h5 {
  font-size: 16px;
}

.suggestion-tags .tags.active {
  color: #7269ef !important;
}

button.btn-disabled {
  background: #808080a8 !important;
  cursor: not-allowed !important;
  color: #fff;
}

button.btn-disabled:hover {
  color: #fff !important;
}

.btn-disabled {
  background: #808080a8 !important;
  cursor: not-allowed !important;
  color: #fff;
}

.right-chat-box-dropdown .dropdown button {
  width: 100%;
  background: #7269ef;
  border: none !important;
  padding: 11px;
  border-radius: 0 12px;
  color: #fff;
  white-space: nowrap;
}

.right-chat-box-dropdown .dropdown-menu.show {
  width: 100%;
  text-align: center;
  border: none;
}

.prompt-box .dropdown-item p {
  background: #ededed;
  padding: 7px 22px;
  margin: 6px 0;
  border-radius: 12px;
  text-align: left;
  white-space: normal;
}

.dropdown-input-area {
  border: 2px dashed rgb(204, 204, 204);
  padding: 15px 35px;
}

.uplaod-document-area {
  position: relative;
  border: 1px dashed #00000069 !important;
  padding: 17px;
  border-radius: 12px;
}

.uplaod-document-area label {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
}

.uplaod-document-area input {
  width: 264px;
  position: absolute;
  left: -37px;
  top: -11px;
  opacity: 0;
  height: 65px;
}

.uplaod-document-area .dropdown-item.active,
.dropdown-item:active {
  background-color: unset !important;
  color: #000 !important;
}

.uplaod-document-area .dropdown-item:focus,
.dropdown-item:hover {
  background: none !important;
}

.right-chat-box-dropdown {
  margin: 10px 0 10px;
}

.right.chat.box {
  border-radius: 24px;
  height: 76vh;
  max-height: 719px;
  overflow: auto;
}

/* vishal */
.mid-chat-box {
  border-radius: 21px;
  border: 1px solid #7269ef;
  max-height: 100%;
}

.chatbox-header {
  padding: 8px 25px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #7269ef;
  position: relative;
}

.chatbox-footer {
  border-top: 1px solid #7269ef;
  position: relative;
  padding-top: 6px;
}

.chatbox-body {
  padding: 3px 23px;
  height: 442px;
  overflow: auto;

}

.left-chat-box {
  border-radius: 0 16px 16px 16px;
  white-space: pre-line;
  margin-top: 14px;
}

#chat-response-id {
  white-space: pre-line;
  margin-top: 14px;
  font-size: 14px;
  color: #000;
  font-weight: 400;
  /* min-height: 264px; */
  resize: none;
}

.inner-transcription-box.text-area-box #chat-response-id {
  min-height: 264px;
}

.left-chat-boxes {
  max-height: 796px;
  overflow: auto;
}

.left-chat-boxes,
.right-chat-boxes {
  /* overflow: auto !important; */
  /* height: 800px !important; */
  margin: 0 0 15px 0;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  padding: 7px 0 10px;
  border: 1px solid #7269ef;
  border-radius: 22px;
  max-height: 100%;
}

.left-chat-box p {
  background: #f9f9f9;
  padding: 6px 18px;
  border-radius: 2px 15px 15px 15px;
  display: inline-block;
  margin: 0 0 3px 0;
  /* box-shadow: 0px 4px 20px rgb(0 0 0 / 8%); */
  /* cursor: pointer; */
  border: 1px solid #0000001f;
}

.right-chat-box p {
  background: #fff0a3;
  padding: 6px 18px;
  border-radius: 15px 15px 0 15px;
  display: inline-block;
  word-break: break-word;
  /* box-shadow: 0px 4px 20px rgb(0 0 0 / 8%); */
}

.privacy-box a {
  color: #000;
  text-decoration: none;
  margin: 0 12px 0 0;
  text-align: right;
  display: inline-block;
}

.right-chat-box-dropdown .dropdown-menu.show {
  width: 100%;
  text-align: center;
  transform: unset !important;
  border: none;
  position: relative !important;
  background: transparent;
  box-shadow: unset;
}

.prompt:hover,
.btn:focus-visible {
  background: #fff0a3 !important;
  border: unset !important;
  color: #000 !important;
}

.privacy-box {
  text-align: right;
}

.social-handle a {
  margin: 0 17px 0 0;
}

.right-chat-box {
  text-align: right;
  margin: 12px 0 0 0;
}

.upload-image-outer {
  position: absolute;
  left: 30px;
}

.mid-chat-box form {
  align-items: center;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  margin: 45px 20px 12px;
  padding: 7px 11px;
  position: relative;
  border: 1px solid #00000021;
  justify-content: space-between;
}

.chat-footer-left {
  display: flex;
  align-items: center;
  width: 100%;
}

.inner-content p {
  white-space: normal;
  font-size: 14px;
  margin: 0;
  box-shadow: unset;
  padding: 0;
}

.mid-chat-box input,
.mid-chat-box textarea {
  overflow: auto;
  resize: none;
  font-size: 13px;
}

.read-more.btn.btn-primary {
  display: block;
  margin-bottom: 0 !important;
  padding: 7px 15px !important;
  width: auto;
  font-size: 15px;
  border-radius: 10px;
  text-align: center;
  margin: 5px auto;
  background: #fff0a3;
  color: #000;
}

.inner-chatbot {
  min-height: calc(100vh - -130px);
  padding: 79px 0 50px;
}

footer {
  padding: 15px;
  border-top: 1px solid #7269ef2e;
}

.attach_file,
.attach_file-light {
  background-image: url("../assests/images/file.svg");
}

.attach_file {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  background-color: initial;
  background-position: 50%;
  background-size: cover;
  border: none;
  float: right;
  height: 30px;
  margin-right: 10px;
  -webkit-user-select: none;
  user-select: none;
  width: 30px;
}

.mid-chat-box input {
  display: none;
}

textarea {
  -ms-overflow-style: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 10px;
  max-height: 25vh;
  overflow-y: visible;
  padding: 0 10px 0 0;
  scrollbar-width: none;
  width: 100%;
}

.submit,
textarea {
  background-color: initial;
  border: none;
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  /* -webkit-user-select: none;
  user-select: none; */
}

a.menu-content-area.dropdown-item {
  height: 250px;
  overflow: auto;
  min-height: 250px;
}

select#formBasicEmail {
  height: 48px;

}

a.update-btn.mb-2 {
  color: white;
}

.cancel-area-box {
  padding: 0 !important;
  border: none !important;
  box-shadow: unset !important;
}

.cancel-area-box button {
  width: 50% !important;
}

.cancel-area-box .modal-body {
  padding-bottom: 0 !important;
}

.cancel-plan-box-area .modal-content {
  width: 80%;
  margin: 0 auto;
}

.cancel-area-box h5 {
  font-size: 20px !important;
}

/* .submit,
.submit-light {
  background-image: url("../assests/images/save.svg");
} */
.submit {
  -webkit-touch-callout: none;
  align-items: center;
  border: 1px solid #7269ef69;
  border-radius: 50%;
  box-shadow: 0 3px 8px rgba(0, 0, 0, .24);
  color: #7269ef;
  display: flex;
  font-size: 20px;
  min-height: 50px;
  height: 50px;
  justify-content: center;
  margin-left: 8px;
  margin-top: 0;
  padding: 12px;
  -webkit-user-select: none;
  min-width: 50px;
}

button.send-btn-disabled {
  color: #808080a6;
  border: 1px solid #808080a6;
  cursor: "not-allowed";
}

.prompt {
  position: absolute;
  right: 50px;
  top: 15px;
  background: #fff0a3;
  border: none;
  color: #000;
  border-radius: 10px 10px 0 0;
}

.paragraph-txt {
  font-size: 14px;
  margin-top: 13px;
}

.login-area {
  height: 100vh;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.privacy-area {
  min-height: 600px;
}

.login-right {
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  padding: 30px;
  border: 1px solid #7269ef;
  border-radius: 22px;
  min-width: 610px;
  text-align: center;
}

.login-right h6 {
  font-size: 26px;
  color: #000;
  font-weight: 500;
}

/* .google-login-btn {
  margin: 35px 10px 17px;
} */
.google-login-btn .form-control {
  padding: 14px;
  background: transparent;
  border: 1px solid;
}

.google-login-btn button {
  background: #fff0a3 !important;
  width: 50%;
  padding: 10px 0;
  font-size: 17px;
  font-weight: 600;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  border: none !important;
}

.login-right p {
  color: #5d5d5d;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 51px 30px;
}

.login-right input::placeholder {
  font-size: 14px;
}

.login-right input {
  border: 1px solid #ccc !important;
  border-radius: 15px;
}

.login-bottom-btn button {
  width: 100%;
  background: #7269ef !important;
  border: none !important;
  padding: 11px;
  color: #fff !important;
  border-radius: 0 12px;
  font-size: 18px;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
}

.login-bottom-btn {
  margin-top: 28px;
}

.on-boarding-login {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-left {
  background: linear-gradient(239deg,
      rgba(255, 240, 160, 1) 0%,
      rgba(113, 104, 236, 1) 49%,
      rgba(59, 46, 228, 1) 100%);
  height: 100vh;
}

strong {
  display: block;
}

.login-left img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.dropdown-toggle.show::after {
  border-top: 0;
  vertical-align: middle;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle::after {
  vertical-align: middle !important;
}

.privacy-list li {
  list-style: circle;
  margin-left: 21px;
}

.privacy-box .privacy-content:hover {
  color: #000;
}

.privacy-list {
  min-height: unset;
  margin-bottom: 17px;
}

.privacy-content {
  /* cursor: pointer; */
  margin: 0 18px;
}

.privacy-content {
  margin-top: 120px;
  margin-bottom: 49px;
}

.privacy-content h2 {
  margin-bottom: 12px;
}

.signup-error input {
  border: 1px solid red !important;
}

.signup-error label span {
  color: red !important;
}

.signup-error label span a {
  color: red !important;
}

.terms-label label {
  float: left;
}

.upgrade-to-pro-style {
  cursor: pointer;
  text-align: center;
  padding: 12px;
  border: 1px solid #7269ef;
  border-radius: 0 15px 0 15px;
  margin: 12px 12px;
  width: 22%;
  color: #000;
  margin: 20px auto;
  box-shadow: 0px 20px 60px 0px rgba(10.207540760869565, 14.185793738554464, 20.999999999999996, 0.1);
}

textarea#input_focused {
  margin-top: 0;
}

.upgrade-to-plan-style {
  cursor: pointer;
  text-align: center;
  padding: 4px;
  border: 1px solid #7269ef;
  border-radius: 0 15px 0 15px;
  margin: 12px 12px;
  width: 50%;
  color: #000;
  margin: 20px auto;
  box-shadow: 0px 20px 60px 0px rgba(10.207540760869565, 14.185793738554464, 20.999999999999996, 0.1);
  white-space: nowrap;
}

.google-logo-style {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.bookmark-img {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.history-obj-active {
  border: 1px solid #655dd636 !important;
  background: #655dd636 !important;
}

.modal {
  background: #000000b5;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: #fff;
  padding: 22px;
  border-radius: 32px;
  /* backdrop-filter: blur(5px); */
}

.pdf-content-right-area .short_info p strong {
  color: #000;
  font-weight: 700;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  color: #000;
  background-color: #fff0a3;
  border-color: #fff0a3;
  box-shadow: unset;
}

.dropdown-menu.show {
  border: none;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  right: -30px !important;
}

/* .chatbox-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
} */
/* 
.chatbox-body::-webkit-scrollbar {
  width: 2px;
  background-color: #919191;
} */

/* .chatbox-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #919191;
} */

::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background-color: #7269ef6e;
  border: 5px solid transparent;
  border-radius: 20px;
}

::-webkit-scrollbar {
  scroll-behavior: smooth;
  width: 16px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #7269ef;
}

.chat-bookmark-area::-webkit-scrollbar {
  width: 2px;
  background-color: #7269ef;
}

.chat-bookmark-area::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #7269ef;
}

.typing-loader {
  width: 6px;
  height: 6px;
  -webkit-animation: line 1s linear infinite alternate;
  -moz-animation: line 1s linear infinite alternate;
  animation: line 1s linear infinite alternate;
  display: inline-block;
}

.typing-loader-main-box .typing-loader {
  display: inline-block;
}

.call-history-box {
  background: #7269EF;
  color: #fff;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 5px;
}

.call-history-box .fa:hover {
  color: #fff;
}

.user-histry {
  text-align: right;
}

.call-history-icon {
  padding: 8px;
}

.video-history-popup-box .modal-content {
  background: #f8f8f8;
}

@-webkit-keyframes line {
  0% {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }

  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }

  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 2);
  }
}

@-moz-keyframes line {
  0% {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }

  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }

  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 2);
  }
}

@keyframes line {
  0% {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }

  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }

  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 2);
  }
}

/* .hero-banner {
  background-image: url("../assests/images/chatbot-hero-bg.webp");
  margin: 0 42px;
  height: 100vh;
  border-radius: 0 0 102px 102px;
} */
.atory-img {
  background-image: url("../assests/images/chatbot-feature-box-1.webp");
  width: 100%;
  padding: 82px;
  border-radius: 31px;
}

.premium-plans {
  color: #000;
  font-size: 18px;
}

.hero-content {
  /* padding: 0 130px 0 2px; */
  color: #000 !important;
}

.hero-content p {
  width: fit-content;
  margin: 0 auto;
  padding: 10px;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 15px;
  font-weight: normal;
}

.hero-content h1 {
  font-size: 70px;
  font-weight: normal;
  color: #fff;
  font-weight: normal;
  margin-bottom: 0;
  display: inline-block;
  border-radius: 5px;
  /* background: linear-gradient(180deg, rgba(255, 240, 163, 0.34902) 0%, rgba(255, 255, 255, 0) 100%); */
  padding: 23px;
}

.hero-top {
  padding-top: 92px;
  position: relative;
}

.chat-history-left span {
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  border: 1px solid #00000052;
  padding: 10px;
  border-radius: 4px;
  padding: 2px 9px 2px 5px;
  width: 100%;
  min-width: 200px;
  margin: 2px 0px;
  background-color: #fff;
  box-shadow: none;
  display: flex;
  justify-content: space-between;
}

.try-ti-buton {
  padding: 13px;
  background: #7269ef !important;
  color: #000;
  font-size: 15px;
  display: inline-block;
  margin: 0 0 0 0;
  padding: 14px 15px;
  border-radius: 0 22px;
}

/* .animated-btn {
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;

} */

@-webkit-keyframes glowing {
  0% {
    background-color: #7269ef;
    -webkit-box-shadow: 0 0 3px #7269ef;
  }

  50% {
    background-color: #7269ef;
    -webkit-box-shadow: 0 0 40px #7269ef;
  }

  100% {
    background-color: #7269ef;
    -webkit-box-shadow: 0 0 3px #7269ef;
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: #7269ef;
    -moz-box-shadow: 0 0 3px #7269ef;
  }

  50% {
    background-color: #7269ef;
    -moz-box-shadow: 0 0 40px #7269ef;
  }

  100% {
    background-color: #7269ef;
    -moz-box-shadow: 0 0 3px #7269ef;
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #7269ef;
    box-shadow: 0 0 3px #7269ef;
  }

  50% {
    background-color: #7269ef;
    box-shadow: 0 0 40px #7269ef;
  }

  100% {
    background-color: #7269ef;
    box-shadow: 0 0 3px #7269ef;
  }
}

@keyframes glowing {
  0% {
    background-color: #7269ef;
    box-shadow: 0 0 3px #7269ef;
  }

  50% {
    background-color: #7269ef;
    box-shadow: 0 0 40px #7269ef;
  }

  100% {
    background-color: #7269ef;
    box-shadow: 0 0 3px #7269ef;
  }
}

.common-padding {
  padding: 90px 0;
}

.sub-heading {
  font-size: 60px;
  color: #000;
  font-weight: 800;
  text-align: center;
  margin-bottom: 25px;
  display: inline-block;
}

.feature-inner {
  display: flex;
  position: relative;
}

.feature-inner span {
  font-size: 102px;
  color: #7269ef;
  position: absolute;
  font-weight: 700;
  display: inline-block;
  padding: 0 22px 0 0;
  right: -22px;
  opacity: 0.2;
  top: 30px;
}

.feature-inner p {
  font-size: 13px;
  line-height: unset;
  text-align: center;
}

.feature-inner h4 {
  color: #000;
  font-weight: 600;
  margin-bottom: 6px;
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
  line-height: unset;
}

.feature-box img {
  transition-duration: .3s;
  transition-property: transform;
  transition-timing-function: ease-out;
  width: 76px;
  display: inherit;
  margin: 0 auto;
  /* padding: 13px; */
  /* border: 2px solid #7269ef; */
  /* border-radius: 62px; */
  /* box-shadow: 8px 10px 8px 0px rgba(10.207540760869565, 14.185793738554464, 20.999999999999996, 0.1); */
  position: absolute;
  left: 38%;
  bottom: 24px;
  text-align: center;
  margin: 0 auto;
}

.feature-box img:hover {
  transform: translateY(-8px);
}

.feature-inner div {
  padding: 0;
  margin-bottom: 19px;
}

.feature-box.main-feature {
  background-image: url("../assests/images/bg-card.png");
  padding: 42px;
  background-repeat: no-repeat;
  height: 100%;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  background-size: cover;
  border-radius: 43px;
  color: #fff;
}

.feature-box {
  background: radial-gradient(at bottom left, #7269ef47 0%, #ffffff 62%);
  padding: 27px 19px 19px 19px;
  background-repeat: no-repeat;
  height: 100%;
  box-shadow: 8px 10px 20px 0px rgba(10.207540760869565, 14.185793738554464, 20.999999999999996, 0.1);
  background-size: cover;
  border-radius: 43px;
  color: #000;
  /* border: 1px solid #6f66e7; */
  height: 441px;
  position: relative;
}

.pricing-area {
  min-height: calc(100vh - 62px);
  padding: 146px 0;
}

.under-appointment {
  padding: 66px 0;
  overflow-x: hidden;
}

.pricing-top-heading-area {
  text-align: center;
  margin-bottom: 45px;
}

.card-detail {
  min-height: 94vh;
}

.click-here-anchor {
  text-decoration: underline;
  color: #7269ef;
  cursor: pointer;
}

/* .card1-dummy {
  min-width: 370px;
} */
.dummy-card-area .modal-dialog {
  width: 100%;
  margin: 0 16px;
}

.dummy-card-area .modal-body {
  padding: 0;
}

.pricing-area {
  background-image: url("../assests/images/pricing-bg.png");
  background-repeat: no-repeat;
  background-position: left;
}

.pricing-content-area {
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  padding: 30px;
  border: 1px solid #7269ef;
  border-radius: 22px;
  height: 100%;
  /* cursor: pointer; */
  position: relative;
}

.form-check-input {
  cursor: pointer;
}

.pricing-top-heading-area p {
  color: #000;
}

.pricing-top-heading-area h4 {
  color: #000;
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 15px;
}

.pricing-content-area:hover img {
  filter: invert(1);
}

.pricing-content-area:hover {
  background: #7269ef;
  color: #fff;
}

.pricing-content-area:hover h5 {
  color: #fff;
}

.pricing-content-area:hover button {
  background: #fff0a3 !important;
  color: #000;
}

.pricing-content-area h5 {
  color: #7269ef;
  margin: 22px 0;
  font-size: 33px;
  font-weight: 800;
}

.pricing-content-area button i {
  margin-left: 5px;
}

.pricing-top p {
  color: #000;
}

.pricing-content-area:hover p {
  color: #fff;
}

.pricing-content-area:hover ul li {
  color: #ffff;
}

.pricing-content-area ul li {
  padding: 5px 0 5px 0;
  font-weight: 500;
  color: #000;
  font-size: 16px;
}

.pricing-content-area button {
  background: #7269ef !important;
  border: none;
  margin-top: 30px;
  padding: 10px 18px;
  font-size: 17px;
  font-weight: 500;
  white-space: nowrap;
  color: #fff;
}

.selected-plan-box {
  background: #7269ef;
}

.selected-plan-box .pricing-top img {
  filter: invert(1);
}

.selected-plan-box .pricing-top p {
  color: #fff;
}

.selected-plan-box h5 {
  color: #ffffff !important;
}

.selected-plan-box ul li {
  color: #fff;
}

.selected-plan-box ul li img {
  filter: invert(1);
}

.selected-plan-box button {
  background: #fff0a3 !important;
  color: #000;
  border-radius: 5px;
}

.pdf-message-style button {
  display: inline-table;
  margin-left: 4px;
}

.cursor-default-important {
  cursor: unset !important;
}

#cursor-pointer-important {
  cursor: pointer !important;
}

.cursor-default-important button {
  cursor: unset !important;
}

.payment-detail-area {
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  padding: 20px 30px;
  border: 1px solid #7269ef;
  border-radius: 22px;
  height: 100%;
  background-color: #fff;
  /* cursor: pointer; */
}

.payment-add-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.payment-add-card h4 {
  color: #000;
  font-weight: 600;
}

.payment-detail-area form {
  border-bottom: 1px solid #8080803d;
  padding-bottom: 30px;
}

.add-card button {
  background: #fff0a3 !important;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  padding: 7px 13px;
  border: none !important;
  white-space: nowrap;
}

.add-card button i {
  margin-right: 6px;
}

.card-detail-box {
  display: flex;
  justify-content: space-between;
  margin: 5px 0 5px;
  align-items: center;
}

.cvv-txt {
  border: 1px solid;
  padding: 2px 17px;
  border-radius: 32px;
  display: inline-block;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
}

.saved-view-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.card-detail-box {
  display: flex;
  justify-content: space-between;
}

.card1 {
  background: radial-gradient(at top left, #ffffffa1 0%, #7269ef 47%);
  padding: 30px 16px;
  border-radius: 20px;
  color: #fff;
  width: 80%;
  margin: 12px auto;
  position: relative;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
}

.card2 {
  background: radial-gradient(at top left, #86b8ff 0%, #584fd8 47%);
}

.saved-view-card p {
  color: #7269ef;
  font-weight: 600;
  text-decoration: underline;
}

.saved-view-card h6 {
  font-size: 18px;
  color: #000;
  font-weight: 600;
}

.payment-detail-area .form-label {
  color: #000;
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 500;
}

.form-control:focus {
  /* color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0; */
  box-shadow: unset !important;
}

.payment-detail-area .form-control::placeholder {
  font-size: 14px;
}

.selected-plan-box {
  height: fit-content;
}

button.pay-now-btn {
  background: #7269ef !important;
  border: none;
  margin-top: 15px;
  padding: 9px 40px;
  font-size: 16px;
}

.payment-detail-area .form-control {
  border: none;
  background: #d4d4d457;
  height: 48px;
}

.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.StripeElement {
  max-width: 100%;
}

.payment-detail-area form label {
  width: 100%;
}

.form-detail-confirm-btn button {
  background: #7169ef;
  margin-top: 20px;
  color: #fff;
  padding: 7px 17px;
  font-weight: 600;
  border-radius: 5px;
}

.cool-features-area {
  padding: 0 0 60px 0;
}

.cool-features-area .accordion-item {
  margin-bottom: 25px;
}

.advantage {
  background: linear-gradient(to right, #000000 0, #7269ef 50%, #7269ef 100%);
  padding: 100px 0;
}

.NFTICALLYAdvantageContent .text-light {
  color: #fff !important;
  font-size: 57px;
  font-weight: 800;
  margin-bottom: 15px;
}

.NFTICALLYAdvantageContent p {
  color: #fff;
  font-weight: 300;
}

.card-header {
  padding: 25px;
}

.card {
  border: none;
  border-radius: unset !important;
  border-bottom: 1px solid #80808036;
}

.awesome-inner-box span {
  font-size: 42px;
  margin-bottom: 30px !important;
  display: block !important;
}

.card .dropdown-top .btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  background: #7269ef !important;
}

.capabilities-section .card .dropdown-top .btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  background: transparent !important;
}

.card button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link.collapsed {
  font-size: 19px;
  font-weight: 600;
  text-decoration: none;
  text-transform: unset !important;
  width: 100%;
  text-align: left;
}

button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link {
  text-transform: unset !important;
  text-decoration: none;
  font-size: 19px;
  color: #000 !important;
  font-weight: 600;
  width: 100%;
  text-align: left;
}

.text-left {
  text-align: left;
}

.attorney-section ul li {
  margin: 16px 0 20px 0;
  line-height: 22px;
  list-style: unset !important;
}

.awesome-section.common-padding {
  background-image: url("../assests/images/atory-banner\ \(1\).jpg");
  color: #fff;
}

.form-select:focus {
  border-color: unset !important;
  outline: 0;
  box-shadow: unset !important;
}

/* .hero-banner {
  background: url("../assests/images/banner.jpg") no-repeat;
  padding: 22px 0 54px 0;
  background-size: cover;
  background-position: center top;
} */
.hero-banner {
  position: relative;
  /* padding-top: 70px; */
}

.advantage {
  background: url("../assests/images/feature.jpg") no-repeat;
  padding: 74px 0 74px 0;
  background-size: cover;
  background-position: center top;
}

.overlay-layer {
  position: absolute;
  width: 100%;
  height: -webkit-fill-available;
  background: linear-gradient(349deg, rgb(40 8 92 / 28%) 0%, rgb(68 56 230) 100%);

}

.hero-content {
  color: #fff !important;
  position: absolute;
  top: 50%;
  width: 100%;
  padding: 31px;
  left: 50%;
  border-radius: 12px;
  /* background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); */
  transform: translate(-50%, -50%);
  /* box-shadow: 0px 4px 20px rgb(0 0 0 / 14%); */
}

.awesome-inner-box {
  background: #000000ab;
  padding: 35px;
  border-radius: 12px;
  border: 1px solid #fff;
  height: 100%;
}

.awesome-inner-box h4 {
  margin-bottom: 15px;
}

.advantage2 {
  background: #fff;
}

.advantage2 h2.text-light {
  color: #000 !important;
}

.advantage2 p {
  color: #000;
}

.hero-content span {
  color: #fff0a3;
  font-size: 70px;
  font-weight: 800;
}

.main-loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #afaeaee6;
  z-index: 1056;
  top: 0;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.main-login-loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #afaeaee6;
  ;
  z-index: 1056;
  top: 0;
  left: 0;
}

.login-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.plan-disabled {
  background-color: lightgray;
  border: none;
  cursor: unset;
}

.plan-disabled button {
  cursor: default !important;
}

.plan-disabled:hover {
  background: lightgray;
}

.plan-disabled:hover img {
  filter: unset;
}

.plan-disabled:hover h5 {
  color: #7269ef;
}

.plan-disabled:hover ul li {
  color: #000;
}

.plan-disabled:hover button {
  background: #7269ef !important;
  color: #fff;
}

.saved-cards-area {
  height: 400px;
  overflow: auto;
}

.saved-cards-area::-webkit-scrollbar {
  width: 2px;
}

.saved-cards-area::-webkit-scrollbar-track {
  background-color: #7269ef33;
}

.saved-cards-area::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px #7269ef;
}

.selected-card i {
  font-size: 14px;
}

.selected-card {
  position: absolute;
  right: -5px;
  top: -6px;
  background: darkgreen;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.sub-para {
  font-size: 10px;
  text-align: center;
  margin: 23px 0 14px 0;
}

.sub-para1 {
  font-size: 14px;
  text-align: left;
  margin: 0 0 14px 0;
}

.sub-para1 b {
  text-decoration: none;
  color: #7269ef;
  font-size: 14px !important;
}

.hero-banner video {
  width: 100%;
  border-radius: 0 0 102px 102px;
  height: 812px;
  /* filter: blur(2px);
    -webkit-filter: blur(2px); */
}

/* .hero-outer {
    z-index: 999;
    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100vh;
} */
.modal-dialog-centered .try-ti-buton {
  padding: 6px 28px;
  margin: 0;
  color: #fff;
}

.modal-dialog-centered .sub-para {
  color: #7269ef;
  background-color: transparent;
  box-shadow: unset;
  margin: 0;
  border: none;
}

.modal-title.h4 {
  font-size: 26px;
  font-weight: 800;
  color: #000;
}

.login-form-box {
  padding: 41px;
  border-radius: 19px;
  height: 100%;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 3%);
  background: #fff;
  border: 1px solid #00000026;
}

.modal-header {
  border: 0;
}

.btn-close:focus {
  box-shadow: unset !important;
  opacity: 1;
}

.btn-close:after {
  content: "Close";
  font-size: 17px;
  color: #000 !important;
  font-weight: 700;
  opacity: 1 !important;
  position: absolute;
  top: 28px;
  right: 31px;
}

.outer-habit-modal .btn-close:after {
  top: 17px;
}

.btn-close {
  background: unset !important;
}

.form-check-input:checked {
  background-color: #7269ef !important;
  border-color: #7269ef !important;
}

.form-check-input:focus {
  box-shadow: unset !important;
}

.login-form-box h5 {
  color: #000;
  font-weight: 700;
  font-size: 25px;
}

.login-form-box span {
  color: #000;
}

.login-form-box a {
  color: #7269ef;
  text-decoration: none;
  font-weight: 600;
}

/* .modal.show .modal-dialog {
    min-width: 912px;
} */
.hero-gif img {
  width: 90%;
  margin: 0 auto;
  display: block;
  border-radius: 52px;
  border: 10px solid #7269ef6e;
  object-fit: cover;
  box-shadow: 0px 20px 60px 0px rgba(10.207540760869565, 14.185793738554464, 20.999999999999996, 0.1);
}

.assests {
  position: absolute;
  top: 50%;
  border-radius: 42px 0 42px 42px;
  left: 21%;
  width: 173px;
  box-shadow: 0px 20px 60px 0px rgba(10.207540760869565, 14.185793738554464, 20.999999999999996, 0.1);
}

.swal2-popup.swal2-modal.swal2-icon-warning.swal2-show {
  border-radius: 42px !important;
  background: #fff !important;
}

.swal2-container.swal2-top,
.swal2-container.swal2-center,
.swal2-container.swal2-bottom {
  grid-template-columns: auto minmax(0, 1fr) auto;
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgb(0 0 0 / 86%);
  backdrop-filter: blur(5px);
}

.swal2-styled.swal2-confirm {
  padding: 9px 30px !important;
  border-radius: 0 17px !important;
  box-shadow: 0px 20px 60px 0px rgba(10.207540760869565, 14.185793738554464, 20.999999999999996, 0.1);
}

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #7066e0 !important;
  color: #fff;
  font-size: 1em;
  padding: 9px 30px;
  border-radius: 0 17px;
}

button.swal2-cancel.swal2-styled.swal2-default-outline {
  background: #7b7a7a !important;
}

.current-plan {
  position: absolute;
  top: -55px;
  left: 49%;
  background: #fff !important;
  color: #7269ef;
  border: 1px solid #7269ef;
  border-radius: 42px;
  color: #fff;
}

#swal2-title {
  color: #000 !important;
}

.dropdown-top.mobile-tab {
  display: none;
}

.pricing-content-area .terms-label label {
  color: #fff;
}

.pricing-content-area a {
  color: #fff0a3;
  text-decoration: none;
}

.pricing-content-area .form-check-input:checked {
  background-color: transparent !important;
  border: 1px solid #fff !important;
}

/* .capabilities-section h2:after {
  content: "\f078";
  position: absolute;
  font-family: "FontAwesome";
  font-size: 13px;
  right: 37px;
  pointer-events: none;
} */
.capabilities-section h2 {
  display: flex;
  align-items: center;
}

.privacy-area {
  background-image: url("../assests/images/pricing-bg.png");
  background-repeat: no-repeat;
  background-position: left;
}

.inner-content {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.error {
  color: red;
}

.error-area h1 {
  width: auto;
  display: inline-block;
  font-size: 7em;
  line-height: 100%;
  letter-spacing: 0.2em;
  text-shadow: 8px -3px 0 rgba(240, 85, 85, 0.6),
    -10px 5px 0 rgba(72, 104, 244, 0.6), 9px 7px 0 rgba(255, 219, 89, 0.6);
  transition: all 0.2s ease;
}

.error-area {
  text-align: center;
  height: 100vh;
}

.error-area h1:hover {
  text-shadow: 4px -1px 0 rgba(240, 85, 85, 0.6),
    -5px 2px 0 rgba(72, 104, 244, 0.6), 6px 3px 0 rgba(255, 219, 89, 0.6);
  transition: all 0.2s ease;
}

.error-area h3 {
  font-size: 2.5em;
}

.border-red {
  border-radius: 5px;
  border: 1px solid red !important;
}

.error-area span {
  cursor: pointer;
  padding: 15px 15px;
  color: white;
  font-weight: bold;
  font-size: 0.9em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  background-color: #7269ef;
  box-shadow: -7px 7px 0 rgba(72, 104, 244, 0.7),
    -12px 12px 0 rgba(255, 219, 89, 0.8);
  transition: all 0.2s ease;
}

.error-area a:hover {
  box-shadow: -3px 3px 0 rgba(72, 104, 244, 0.7),
    -6px 6px 0 rgba(255, 219, 89, 0.8);
  transition: all 0.2s ease;
}

.modal-area-box .modal-dialog {
  width: 100%;
}

.modal-area-box label.form-label {
  display: flex;
  justify-content: left;
  font-weight: 600;
  margin-bottom: 3px;
}

.reason-boxes textarea#formFile {
  margin-top: 0;
  border-radius: 12px;
  padding: 12px 21px;
}

.reason-boxes select {
  padding: 13px;
  border-radius: 8px;
  border-color: #0000001f;
  background-color: #fff;
}

.reason-boxes .phone-input input {
  width: 100% !important;
  /* height: 42px !important; */
  padding: 24px 49px;
  border-color: #0000001f !important;
  border-radius: 8px !important;
}

.reason-boxes input[type="radio" i] {
  font-size: 20px;
  width: 25px;
  height: 26px;
  vertical-align: middle;
}

.text-insu {
  background: transparent !important;
  box-shadow: none !important;
  font-weight: 700;
  margin-bottom: 0;
  padding: 0 !important;
  border: 0 !important;
}

.modal-area-box textarea#formFile {
  margin-top: 0;
}

.phone-input input {
  width: 100% !important;
  height: initial !important;
}

.overflow {
  /* max-height: 760px; */
  /* overflow: auto; */
  padding: 0 20px;
}

section.attorney-section {
  padding-bottom: 0;
}

button.try-ti-buton:disabled {
  background: #a9a9a973 !important;
}

button.get-in-touch {
  background: #fff0a3 !important;
  border: none;
  color: #000 !important;
}

.call-to-action-right button {
  margin-top: 0;
}

.call-to-action-right {
  justify-content: end;
  display: flex;
}

.call-to-action-left h4 {
  color: #fff;
  font-size: 30px;
  font-weight: 800;
}

.call-to-action-left h4 span {
  color: #fff0a3;
}

.top-call-to-action .container {
  margin-top: 0;
  margin-bottom: 70px;
}

section.features-section {
  padding-bottom: 18px;
}

.call-to-action-left p {
  color: #fff;
  margin-top: 10px;
  font-size: 19px;
}

.selected-card.delete-card {
  right: 31px;
  background: #f00;
}

.call-to-action .container {
  background: linear-gradient(268.68deg,
      #b0e8f7 25.8%,
      rgb(114 105 239) 90.26%);
  padding: 50px;
  border-radius: 20px;
  /* margin-top: 70px; */
}

.call-to-action {
  padding: 69px 0;
}

.pricing-contact-btn {
  text-align: center;
}

.chat-history-left {
  box-shadow: none;
  text-align: center;
}

.loader {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
  opacity: 0.5;
  border: 3px solid #09acfd;
  -webkit-animation: loader 1s ease-in-out infinite alternate;
  animation: loader 1s ease-in-out infinite alternate;
}

.loader:before {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border: 3px solid #09acfd;
}

.loader:after {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border: 3px solid #09acfd;
}

@keyframes loader {
  from {
    transform: rotate(0deg) scale(1, 1);
    border-radius: 0px;
  }

  to {
    transform: rotate(360deg) scale(0, 0);
    border-radius: 50px;
  }
}

@-webkit-keyframes loader {
  from {
    -webkit-transform: rotate(0deg) scale(1, 1);
    border-radius: 0px;
  }

  to {
    -webkit-transform: rotate(360deg) scale(0, 0);
    border-radius: 50px;
  }
}

.loader3 {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
  text-align: left;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.loader3 span {
  position: absolute;
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background: rgb(255 255 255);
  animation: loader3 1.5s linear infinite;
}

.loader3 span:last-child {
  animation-delay: -0.9s;
  -webkit-animation-delay: -0.9s;
}

.legal-research-area {
  height: 300px;
  min-height: 300px;
  overflow: auto;
}

.feature-box.main-feature span.text-light {
  color: #9289ff !important;
}

button.get-in-contact-btn {
  background: #fff0a3 !important;
  color: #000;
}

@keyframes loader3 {
  0% {
    transform: scale(0, 0);
    opacity: 0.8;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@-webkit-keyframes loader3 {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.8;
  }

  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}

.logo {
  width: 222px;
  height: unset !important;
}

.card button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link.collapsed:after {
  content: "\f078";
  position: absolute;
  font-family: "FontAwesome";
  font-size: 13px;
  right: 37px;
  pointer-events: none;
}

.card button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link:after {
  content: "\f077";
  position: absolute;
  font-family: "FontAwesome";
  font-size: 13px;
  right: 37px;
  pointer-events: none;
}

.microphone-status {
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  margin-top: 9px;
  font-weight: 700;
  color: #7269ef;
}

.send-record-btn {
  display: flex;
  align-items: center;
}

.microphone-section-area {
  display: flex;
}

/* .microphone-section-area .microphone-icon-container.submit {
  margin: 0 auto 4px;
} */
/* 
.microphone-section-area {
  width: 65px;
} */
/* .microphone-section-area {
  margin-left: 13px;
} */
.prompt-box.right-chat-box-dropdown.dropdown-spacing .dropdown-menu.show {
  right: 0 !important;
}

.dropdown-spacing a.dropdown-item p {
  margin: 0 !important;
}

.dropdown-spacing a.dropdown-item {
  padding: 0;
}

/* .profile-medication-area form {
    background: #eae9fd80;
    padding: 18px;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
    border-radius: 15px;
    margin-top: 10px;
    border: 1px solid #80808012;
} */
.profile-medication-area h6 {
  font-weight: 700;
  color: #000;
  font-size: 19px;
  margin-bottom: 11px;
  text-transform: uppercase;
}

.profile-medication-area {
  margin-top: 25px;
}

.profile-content-area label {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
}

.profile-content-area input {
  border: 1px solid #80808038;
  background: transparent;
  padding: 11px;
}

.profile-content-area .form-select {
  padding: 11px;
  cursor: pointer;
}

.profile-content-area input::placeholder {
  font-size: 14px;
  color: darkgray;
}

.profile-medication-area p {
  color: #7269ef;
  font-weight: 700;
  text-align: end;
  font-size: 14px;
  text-decoration: underline;
  margin-top: 5px;
}

.dosage-area {
  display: flex;
  align-items: stretch;
}

.btn.btn-primary:hover {
  color: #fff !important;
}

.profile-content-area .form-control:focus {
  color: #212529;
  background-color: transparent;
  border-color: #dadae2;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}

.mg-ml-box-area h5 {
  font-size: 15px;
}

.mg-ml-box-area {
  border: 1px solid #dadae2;
  height: 45px;
  align-items: center;
  display: flex;
  width: fit-content;
  padding: 10px;
  border-radius: 5px;
  margin-left: 5px;
  background: #d3d3d36e;
}

.height-select-area {
  display: flex;
  align-items: center;
}

.height-select-area button#dropdown-basic-button {
  height: 45px;
  border-radius: 5px;
  margin-left: 5px;
  background: lightgray;
  border: none;
  color: #000;
  font-size: 15px;
  font-weight: 500;
}

.dropdown-menu.show {
  min-width: fit-content !important;
  width: fit-content !important;
}

.profile-content-area h2 {
  font-size: 22px;
  font-weight: 700;
}

.dosage-area button#dropdown-basic-button {
  height: 45px;
  border-radius: 5px;
  background: lightgray;
  border: none;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  margin-left: 5px;
}

.height-select-area button#dropdown-basic-button:active {
  background: lightgray !important;
  color: #fff !important;
}

button.profile-submit-btn.btn.btn-unset {
  background: #7269ef;
  color: #fff;
  border-radius: 5px;
  margin-top: 30px;
  padding: 8px 20px;
  font-weight: 500;
  border-radius: 12px 0 12px 0;
}

.right-chat-box-dropdown .dropdown-menu.show {
  width: unset !important;
}

.fa-trash {
  color: red;
  cursor: pointer !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}




.overlay-layer {
  background: linear-gradient(349deg, rgba(40, 8, 92, .28), #4438e6);
  height: -webkit-fill-available;
  height: 100%;
  position: absolute;
  width: 100%;
}


.chat-history-area.chat-history-left .fa-trash,
.chat-bookmark-area .fa-trash {
  margin-top: 5px;
}

.atorny-card {
  width: 100%;
  height: 310px;
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
  border-radius: 20px;
}

.atroy-inner-box {
  padding: 27px;
  background: #fff;
  position: absolute;
  width: 95%;
  height: 95%;
  border-radius: 20px;
  z-index: 5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  padding: 20px;
  cursor: pointer;
}

.atorny-card:before {
  content: "";
  position: absolute;
  width: 111%;
  height: 200%;
  background: #40E0D0;
  background: linear-gradient(to right, #7269ef, #7369f0, #b0e8f7);
  animation: glowing01 5s linear infinite;
  /* transform-origin: center; */
  animation: glowing 5s linear infinite;
}

@keyframes glowing {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.atroy-inner-box b {
  font-size: 20px;
}

.registration-area {
  /* background: url("../images/hero-banner.png"); */
  background-repeat: no-repeat;
  height: 100vh;
  background-position: right;
  display: flex;
  justify-content: center;
  align-items: center;
}

.registration-left h4 {
  color: #000;
  font-size: 38px;
  font-weight: 800;
  position: relative;
  padding-left: 20px;
}

/* start responsive */
.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}

.h-custom {
  height: calc(100% - 73px);
}

.insurance-type-area {
  text-align: left;
}

.insurance-type-area input {
  margin: 0 5px 0 0;
  cursor: pointer;
}

.insurance-type-field input {
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 10px;
}

.reason-boxes input[type='radio'] {
  accent-color: #7269ef;
}

.reason-boxes .form-control {
  padding: 13px;
  border-radius: 8px;
}

.tags {
  background: #e8e7ed;
  color: #000;
  padding: 1px 10px;
  border-radius: 11px;
  font-size: 14px;
  text-align: left;
  margin-right: 7px;
  display: inline-block;
  margin-bottom: 6px;
  border: 1px solid #7269ef;
  min-width: -moz-fit-content;
  min-width: fit-content;
  margin-bottom: 7px !important;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
}

.selected .fa-trash {
  color: #fff;
}

.tags.tag-select {
  background-color: #7269ef !important;
  color: #fff !important;
}

select.insurance-type-field {
  width: 100%;
  height: 50px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 10px;
  color: #00000094;
}

.resons-to-visit {
  padding: 7px 15px !important;
}

.appointment-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.appointment-area ul {
  min-height: fit-content;
  border: 2px solid #ffffff;
  display: flex;
  height: 50px;
  align-items: center;
  border-radius: 60px;
  overflow: hidden;
}

.appointment-area h2 {
  margin-bottom: 0;
}

.appointment-area ul li {
  min-width: 150px;
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 17px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 140px;
  cursor: pointer;
  padding: 14px;
}

.appointment-area ul li.active {
  min-width: 150px;
  background: #fff;
  color: #000;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 140px;
}

.appointment-content-area-image img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  min-width: 100px;
  min-height: 100px;
}

.appointment-content-area {
  padding: 1px;
  border-radius: 20px;
  margin-bottom: 13px;
  background: #7269ef14;
}

.appointment-content-area-left {
  display: flex;
  align-items: center;
}

.appointment-info-mid {
  display: flex;
  align-items: center;
}

.appointment-content-info {
  margin-left: 15px;
}

.appointment-content-info h6 {
  font-size: 20px;
  font-weight: 700;
}

.appointment-info-mid p {
  color: gray;
  margin-right: 15px;
}

.appointment-info-mid p span {
  color: #000;
  font-weight: 500;
}

.appointment-content-info h5 {
  font-size: 17px;
  color: #7269ef;
  margin-top: 10px;
}

.appointment-content-info p {
  font-weight: 500;
}

.appointment-content-area-right {
  text-align: end;
}

.appointment-content-area-right p {
  font-size: 14px;
  color: gray;
  font-weight: 500;
}

.appointment-content-area-right i.fa.fa-angle-down {
  background: #7269ef;
  color: #fff;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  border-radius: 50%;
  font-weight: 800;
  font-size: 18px;
}

.appointment-content-area-right-bottom {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 8px;
}

.appointment-content-area-right-bottom button {
  background: #7269ef;
  color: #fff;
  font-weight: 600;
  border-radius: 100px;
  font-size: 15px;
  padding: 4px 10px;
  white-space: nowrap;
}

.appointment-content-area-right-bottom i.fa.fa-calendar-o {
  background: #F44336;
  color: #fff;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 6px;
  border-radius: 50%;
}

i.fa.fa-phone {
  background: #4CAF50;
  color: #fff;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  /* margin-right: 5px; */
}

div:where(.swal2-icon).swal2-success {
  margin: 0 auto !important;
}

div:where(.swal2-container).swal2-center>.swal2-popup {
  padding: 30px !important;
  border-radius: 25px;
}

.update-btn {
  width: fit-content;
  background: #7269ef;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  padding: 10px 20px;
  display: inline-block;
  text-decoration: none;
}

.appointment-content-area-image {
  text-align: center;
}

.login-box {
  padding: 54px 44px;
  background: #fff;
  border-radius: 23px;
  margin-top: 14px;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
}

.login-box input {
  padding: 8px;
  width: 100%;
  background: transparent;
  border: 1px solid #00000038;
  margin-top: 12px;
  border-radius: 9px;
  padding: 13px;
}

.login-box .form-check {
  display: flex;
  align-items: center;
}

.login-box a {
  color: #7269ef;
  font-weight: 700;
}

.login-heading {
  font-size: 43px;
  font-weight: 700;
  margin-bottom: 15px !important;
}

.no-data {
  width: 320px !important;
  height: 320px !important;
}

.outer-no-data p {
  font-size: 27px;
  font-weight: 700;
}

select {
  padding: 7px;
  border-radius: 5px;
  width: 100%;
  border-color: #0000001a;
  background-color: #fff;
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.medication-table .form-control {
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  width: 100%;
}

#counter {
  position: absolute;
  font-size: 11px;
  color: #7269ef;
  right: 42%;
  bottom: -31px;
}

.appointment-content-area .accordion-button.collapsed {
  border: none;
  background: #f4f3fe;
}

.appointment-content-area .accordion-item {
  background: transparent;
  border: none;
}

.appointment-content-area .accordion-button:not(.collapsed) {
  background-color: #f4f3fe;
}

.appointment-content-area .accordion-button:focus {
  box-shadow: none;
}

.pagination {
  display: flex;
  align-items: center;
}

.page-item.active .page-link {
  background-color: #7269ef;
  border-color: #7269ef;
}

.page-link {
  color: #7269ef;
}

.btn.btn-primary {
  background: #7269ef !important;
  border: none !important;
  color: #fff !important;
  cursor: pointer;
}

.appointment-content-area .accordion-button {
  justify-content: space-between;
}

.accordion-button::after {
  background-image: url("../assests/images/down.svg");
  position: absolute;
  right: 40px;
  bottom: 33px;
}

.family-box-body .accordion-button::after {
  content: "\f107";
  font-family: fontAwesome;
  background: #7269ef;
  color: #fff;
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  border-radius: 52px;
  font-size: 27px;
  position: absolute;
  top: 25px;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../assests/images/down.svg");
}

/* .family-box-body .accordion-button:not(.collapsed)::after {
  background-image: url("../assests/images/down-family.svg");
} */
.family-box-body .profile-card {
  margin-top: 2px !important;
}

.verify-input-field input {
  min-width: 3em;
  width: 3em;
}

.pagination {
  align-items: center;
  justify-content: center;
}

.appointment-content-area-right-bottom button:hover {
  background-color: #7269ef;
  color: #fff;
}

.swal2-show {
  animation: swal2-show .3s;
  justify-content: center !important;
  justify-items: center !important;
}

.password {
  position: absolute;
  top: 68%;
  right: 4%;
  cursor: pointer;
}

.user-details-section {
  padding: 42px 0px;
  min-height: calc(100vh - 62px);
}

.user-under-box {
  margin: 0 0 15px 0;
  padding: 11px 25px 14px 25px;
  /* overflow: auto; */
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 2px 24px 0px rgba(114, 105, 239, 0.15);
}

.report-box h4 {
  color: #716aee;
  font-size: 16px;
  font-weight: 500;
}

.report-box p {
  font-size: 12px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  color: #716aed;
  justify-content: end;
}

.report-box.active {
  border: 1px solid #7269ef;
  color: #ffffff !important;
  background: #7269ef;
}

.report-box.active h4,
.report-box.active p {
  color: #fff;
}

.report-box.active img {
  filter: brightness(0) invert(1);
}

.report-box .active {
  border: 2px solid blue !important;
}

.report-box {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 2px 24px 0px rgb(114 105 239 / 10%);
  display: inline-block;
  padding: 12px 13px;
  text-align: center;
  margin: 13px 9px 13px 0;
  min-width: fit-content;
}

.user-under-box p {
  font-size: 13px;
  line-height: 23px;
}

/* .key-point-box span {
  color: #7269ef;
  font-size: 13px;
  text-align: right;
  display: block;
  font-weight: 600;
} */

.user-under-box h1 {
  font-size: 34px;
  font-weight: 700;
  color: #000;
  padding: 3px;
  border-radius: 13px;
}

a.btn-purple {
  background: #7269ef;
  padding: 9px;
  color: #fff;
  border-radius: 4px;
  padding: 10px 22px;
  float: right;
  margin-top: 11px;
}

.text-left {
  text-align: left;
}

.key-point-box.notes-box {
  overflow: auto;
  max-height: 332px;
}

.user-inner-box.main-under-user {
  border-bottom: 1px solid #cbc9c9;
}

.user-inner-box.main-under-user h5 {
  margin-bottom: 8px;
}

.user-inner-box {
  padding: 13px 0;
  text-align: center;
}

.user-inner-box h4 {
  font-weight: 700;
}

.email {
  color: #7269ef;
  font-weight: 700;
  font-size: 19px;
  margin: 8px 0;
}

.details-user {
  margin: 14px 0 0 0;
  text-align: center;
}

.selected {
  border: 3px solid #7269ef !important;
  background: #7269ef !important;
  color: #fff;
}

.user-inner-box.profile-main img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 158px;
}

.accordion_animate {
  animation: fadeInRight 1s ease-in-out;
}

@keyframes fadeInRight {
  to {
    opacity: 0;
    transform: translateX(300px);
  }

  from {
    opacity: 1;
  }
}

.swal2-styled.swal2-confirm a {
  color: #fff;
}

.swal2-cancel.swal2-styled a {
  color: #fff;
}

.appointment_innerbox {
  padding-right: 290px;
  min-height: 91px;
  display: flex;
  align-items: flex-start;
}

.appointment_fullcontent {
  padding: 20px 12px 0 20px;
  position: relative;
  display: none;
}

.appointment_togglebtn {
  background: #7269ef;
  width: 26px;
  height: 26px;
  display: inline-block;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  line-height: 26px;
  box-shadow: 0 1px 5px rgba(32, 159, 133, 0.2);
  transition: 0.2s ease-in all;
  -moz-transition: 0.2s ease-in all;
  -webkit-transition: 0.2s ease-in all;
  -ms-transition: 0.2s ease-in all;
  -o-transition: 0.2s ease-in all;
  cursor: pointer;
  font-size: 10px;
}

.appointment_togglebtn_past {
  background: #7269ef;
  width: 26px;
  height: 26px;
  display: inline-block;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  line-height: 26px;
  box-shadow: 0 1px 5px rgba(32, 159, 133, 0.2);
  transition: 0.2s ease-in all;
  -moz-transition: 0.2s ease-in all;
  -webkit-transition: 0.2s ease-in all;
  -ms-transition: 0.2s ease-in all;
  -o-transition: 0.2s ease-in all;
  cursor: pointer;
  font-size: 10px;
}

.appointments_box.open .appointment_togglebtn {
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transition: 0.2s ease-in all;
  -moz-transition: 0.2s ease-in all;
  -webkit-transition: 0.2s ease-in all;
  -ms-transition: 0.2s ease-in all;
  -o-transition: 0.2s ease-in all;
}

.appointments_box.open .appointment_togglebtn_past {
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transition: 0.2s ease-in all;
  -moz-transition: 0.2s ease-in all;
  -webkit-transition: 0.2s ease-in all;
  -ms-transition: 0.2s ease-in all;
  -o-transition: 0.2s ease-in all;
}

.appointment-content-image img {
  min-width: 130px;
  height: 130px;
  width: 130px;
  border-radius: 72px;
  object-fit: cover;
  margin-right: 14px;
}

.appointments_box.open .appointment_fullcontent {
  display: block !important;
  margin-left: 95px;
  padding: 0;
}

.appointments_box.request_appointments_box p {
  font-size: 16px;
  color: #4d4d4d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.appointment-content-text {
  width: 90%;
}

.appointments_box.request_appointments_box .appointment_fullcontent p {
  white-space: normal;
}

.appointments_box.open .short_info {
  display: none;
}

.text-right {
  text-align: right;
}

.mark-completed {
  background: #3397ff;
  padding: 6px;
  border-radius: 22px;
  color: #fff;
}

.icons-left {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
  position: relative;
}

.appointments_box.request_appointments_box {
  padding-left: 20px;
  padding-right: 20px;
}

.appointments_box {
  border: 1px solid rgba(138, 138, 138, 0.3);
  border-radius: 15px;
  position: relative;
  margin-bottom: 10px;
  padding: 20px 290px 20px 125px;
  min-height: 130px;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  background: #fff;
  margin-top: 7px;
}

.appointments_box.open .appointment_togglebtn {
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transition: 0.2s ease-in all;
  -moz-transition: 0.2s ease-in all;
  -webkit-transition: 0.2s ease-in all;
  -ms-transition: 0.2s ease-in all;
  -o-transition: 0.2s ease-in all;
}

.appointment_rightarea {
  position: absolute;
  right: 20px;
  top: 9px;
  bottom: 20px;
  width: 244px;
}

.appointment-content-image img {
  min-width: 80px;
  height: 80px;
  width: 80px;
  border-radius: 72px;
  object-fit: cover;
  margin-right: 14px;
}

.appointments_box.open .appointment_fullcontent {
  display: block !important;
  margin-right: 250px;
  margin-top: -27px;
}

.doctor-side .appointment_fullcontent {
  margin-top: -27px !important;
}

.appointments_box.request_appointments_box.doctor-side.open .icons-left {
  height: 51%;
  z-index: 999;
}

.appointment-content-text {
  width: 90%;
}

.appointments_box.request_appointments_box .appointment_fullcontent p {
  white-space: normal;
}

.appointments_box.open .short_info {
  display: none;
}

.appointments_box.request_appointments_box p span {
  color: #060606;
  font-weight: 400;
}

.text-right {
  text-align: right;
}

.mark-completed {
  background: #3397ff;
  padding: 6px;
  border-radius: 22px;
  color: #fff;
}

.login-box .form-check-input[type=checkbox] {
  width: fit-content;
  margin-top: 0 !important;
}

.login-box .form-check-label {
  margin-left: 12px;
}

.appointment-content-text h3 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
}

.short_info.purple-txt {
  margin-top: 7px;
}

.purple-txt {
  color: #7269ef !important;
}

.appointment_fullcontent span {
  font-weight: 400;
}

.completed-text.badge.bg-success {
  background: #7269ef !important;
  padding: 9px 17px;
  border-radius: 22px;
  margin-left: 6px
}

.completed-text.badge.bg-warning {
  background: #ffb100 !important;
  padding: 9px 17px;
  border-radius: 22px;
  margin-left: 6px
}

.appointment-boxes {
  background: #7269ef;
  padding: 25px 0;
  color: #fff;
}

.complete-text.btn.btn-unset {
  background: #7269ef;
  color: #fff;
  border-radius: 42px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
}

.profile-content-area.my-profile-content {
  background: #fff;
  padding: 25px 30px;
  border: 1px solid #00000026;
  border-radius: 20px;
}

.profile-content-area.my-profile-content h2 {
  font-size: 24px;
  margin-bottom: 18px;
  font-weight: 800;
}

.appointments_box.request_appointments_box.open .icons-left {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  bottom: 0;
}

.appointments_box.request_appointments_box.open .appointment_innerbox {
  align-items: self-start;
}

.fa.fa-calendar-plus-o {
  background: #ff6000;
  color: #fff;
  width: 37px;
  min-width: 37px;
  height: 37px;
  min-height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 5px;
  font-size: 14px;
  cursor: pointer;
}

.appointment-box .modal-dialog {
  transform: none;
  max-width: 912px;
  min-width: 692px;
}

.appointment-boxes-inner {
  padding: 16px 0;
  border-top: 1px solid #00000036;
}

.appointment-boxes-inner p {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.appointment-boxes-inner .form-switch .form-check-input {
  width: 3em;
  height: 28px;
}

.appointment-boxes-inner .form-control {
  padding: 11px;
  border-radius: 16px;
  cursor: pointer;
}

.appointment-boxes-inner .form-control:focus {
  border-color: unset !important;
}

.fa.fa-clock-o {
  position: absolute;
  top: 17px;
  right: 17px;
  cursor: pointer;
}

.appointment-boxes-inner .position-relative {
  margin: 0 6px;
}

.menu-profile .dropdown button {
  padding: 0;
  margin: 0;
  background-color: transparent !important;
  margin-right: 12px;
}

.user-details-box h1 {
  font-size: 20px;
}

.user-under-box h5 {
  font-size: 17px;
  color: #000;
  padding: 10px 0;
  border-radius: 4px;
  font-weight: 700;
}

.user-under-box h5 a {
  font-size: 14px;
  cursor: pointer;
  font-style: italic;
}

.key-point-box {
  border-radius: 15px;
  background: #f9f9fd;
  padding: 15px 0 15px 15px;
  border-radius: 15px;
  background: #FFF;
  /* height: 65%; */
  box-shadow: 0px 2px 24px 0px rgba(114, 105, 239, 0.20);
}

.key-point-box-inner {
  max-height: 322px;
  height: 322px;
  overflow: auto;
}

.btn-grad {
  background-image: linear-gradient(to right, #7269ef 0%, #49a09d 51%, #5f2c82 100%);
  padding: 18px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  display: block;
  font-size: 14px;
}

.btn-grad:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.user-details-box {
  padding: 22px;
  border-radius: 20px;
  border: 1.5px solid #7269EF;
  box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.15);
  color: #000;
}

.details-user h5 {
  color: #000;
  font-size: 16px;
}

.add-notes .modal-dialog {
  min-width: 482px;
}

.add-notes .form-control {
  border-radius: 13px !important;
}

.loader1 {
  width: 16px;
  height: 16px;
  position: relative;
  left: -32px;
  border-radius: 50%;
  color: #fff;
  background: currentColor;
  box-shadow: 32px 0, -32px 0, 64px 0;
}

.loader1::after {
  content: '';
  position: absolute;
  left: -32px;
  top: 0;
  width: 16px;
  height: 16px;
  border-radius: 10px;
  background: #FF3D00;
  animation: move 3s linear infinite alternate;
}

@keyframes move {

  0%,
  5% {
    left: -32px;
    width: 16px;
  }

  15%,
  20% {
    left: -32px;
    width: 48px;
  }

  30%,
  35% {
    left: 0px;
    width: 16px;
  }

  45%,
  50% {
    left: 0px;
    width: 48px;
  }

  60%,
  65% {
    left: 32px;
    width: 16px;
  }

  75%,
  80% {
    left: 32px;
    width: 48px;
  }

  95%,
  100% {
    left: 64px;
    width: 16px;
  }
}

.circular-image {
  width: 150px;
  /* Adjust the size as needed */
  height: 150px;
  /* Should be the same as the width for a perfect circle */
  overflow: hidden;
  border-radius: 50%;
  /* Make it a circle */
  margin: 0 auto;
  /* Center the image horizontally */
}

.circular-image img {
  width: 100%;
  /* Ensure the image covers the circular container */
  height: auto;
  /* Maintain aspect ratio */
}

.back-btn a {
  color: #706E6E;
  font-weight: 600;
}

.btn-grad.p-2.mt-3.ms-3.grad_12 {
  background-image: unset;
  color: #7269ef;
  border: 1px solid #7269ef;
}

.login-form-box.terms-condition-box-area .form-check-input[type="checkbox"] {
  min-width: 16px;
  min-height: 16px;
  margin-right: 11px;
  margin-top: 8px;
}

.login-form-box.terms-condition-box-area .mb-3.terms-label.form-check {
  display: flex;
}

.profile-main {
  padding-top: 90px;
}

.back-btn {
  background: #979797;
  color: #fff;
  margin-top: 31px;
  padding: 8px 30px;
  margin-left: 22px;
  display: inline-block;
  border-radius: 12px 0 12px 0;
}

.dosage-area input {
  width: 69%;
}

.medication-form {
  position: relative;
}

.delete-text {
  position: absolute;
  top: 37px;
  right: 28px;
}

.back-btn:hover {
  background: #979797;
  color: #fff;
}

.outer-no-data {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* .user-side i.fa.fa-phone {
  margin-right: 0;
} */

.user-side .icons-left {
  margin-top: 31px;
}

.user-side.open .icons-left {
  margin-top: 4px;
}

.doctor-side .icons-left {
  margin-top: 30px;
}

button.swal2-cancel.swal2-styled {
  border-radius: 0 17px !important;
}

.suggestion-tag {
  box-shadow: unset !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 0 !important;
  border: none !important;
  background: transparent !important;
}

.insurance-type-field {
  margin-top: 8px;
}

.back-btn-box a {
  display: inline-block;
  color: #000;
  margin-top: 31px;
  margin-bottom: 9px;
  border-radius: 10px;
  font-size: 15px;
  cursor: pointer;
}

.user-inner-box.profile-main {
  padding: 0;
}

.test-recomended {
  padding: 3px 12px;
  background: #fff;
  margin: 0 0 7px 0;
}

.add-text {
  color: #7269ef;
  border: 1px solid;
  padding: 1px 7px;
  border-radius: 5px;
  font-size: 13px;
}

.add-text:hover {
  color: #7269ef;
}

.details-user p {
  padding-bottom: 10px !important;
  color: #706E6E;
}

.pricing-area.profiel-details-section {
  padding: 47px 0;
}

.profile-card {
  border-radius: 22px !important;
  border: 1px solid #00000014;
  background: #fff;
  padding: 21px;
}

.profile-img {
  width: 122px !important;
  height: 122px !important;
  object-fit: contain;
  border: 1px solid #7269ef;
}

.chatbox-header h3 {
  font-size: 19px;
}

a#set-appointment {
  color: #7269ef;
  font-weight: 600;
}

.tags-box {
  display: flex;
  overflow: auto;
  align-items: flex-start;
  padding-bottom: 2px;
}

/* .tags-box::-webkit-scrollbar {
  width: 2px;
  background-color: #69677a78;
  height: 4px;
} */
.tags-box::-webkit-scrollbar-thumb {
  background-color: #cacaca;
}

.tags-box::-webkit-scrollbar-thumb:hover {
  background-color: #7269ef;
}

.fa.fa-calendar {
  position: absolute;
  top: 17px;
  right: 19px;
  pointer-events: none;
  cursor: pointer;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #7269ef;
}

.key-point-box p strong {
  border: 1px solid #7f79f0;
  display: inline-block;
  padding: 2px 14px;
  color: #7f79f0;
  border-radius: 9px;
  margin-bottom: 6px;
}

.key-point-box ul li {
  font-size: 13px;
  list-style: disc;
  margin-left: 19px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #7f79f0;
}

.reports-box {
  display: flex;
  overflow: auto;
}

.active {
  color: #7f79f0 !important;
}

.appointment-content-area-image .no-data {
  width: 240px !important;
  height: 220px !important;
}

.downloaded-icon {
  margin-left: 3px;
}

.downloaded-icon .fa.fa-download {
  color: green;
  margin-right: 13px;
  margin-top: 9px;
  cursor: pointer;
}

.downloaded-icon .fa.fa-clipboard {
  color: #3831bd;
}

.login-area-box {
  padding: 52px 0 !important;
  display: flex;
  align-items: center;
}

.recomended-box input[type="checkbox"],
input[type="radio"] {
  accent-color: #7269ef;
  cursor: pointer;
  margin-right: 8px;
}

.recomended-box li {
  font-size: 16px;
  margin-bottom: 12px;
}

.modal-body.recomended-test-box {
  padding: 7px 0;
}

.try-ti-buton.close-btn {
  background: #919191 !important;
  border: none;
  margin-right: 10px;
}

.react-datepicker-wrapper {
  width: 100%;
  cursor: pointer;
}

.tags .fa.fa-clock-o {
  position: unset;
  top: unset;
  right: unset;
  cursor: pointer;
  margin-right: 3px;
  color: #646464;
}

.error-class {
  color: red;
}

.family-inner-box img {
  max-width: 34px;
}


.tags-box-main .tags {
  width: fit-content;
  border-radius: 32px;
  border: none;
  text-align: center;
  margin-bottom: 6px !important;
}

.tags-box-main {
  max-height: 142px;
  overflow: auto;
  height: 100%;
}

.react-datepicker__input-container input {
  cursor: pointer;
}

.tags.active {
  background: #7269ef;
  color: #fff !important;
}

button.verify-bnt {
  position: absolute;
  top: 34px;
  color: #fff;
  background: #6f67ea !important;
  right: 18px;
  font-weight: 700;
  padding: 0px 14px;
  font-size: 12px;
  background: transparent;
  border-radius: 24px;
}

.verified-icon svg {
  position: absolute;
  top: 38px;
  right: 22px;
  /* font-size: 32px; */
  width: 32px;
}

.tags.main p {
  background: transparent;
  box-shadow: none;
  margin: 0;
  padding: 0;
  font-size: 13px;
}

.tags.main h6 {
  font-size: 13px;
}

.tags.main {
  border: none;
  padding: 6px 12px;
  margin-top: 11px;
}

.tags.active .fa {
  color: #fff !important;
}

.appointment-modal .modal-dialog {
  max-width: 762px !important
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  left: -15px !important;
}

.prompt-box.right-chat-box-dropdown.dropdown-spacing .dropdown {
  display: block;
}

footer {
  padding: 18px 0;
}

.chat-animated-button {
  display: none;
}

.chat-animated-button button img {
  width: 43px;
}

.animated-btn {
  background: transparent !important;
  /* padding: 9px 22px;
  border: 1px solid #7269ef;
  color: #7269ef; */
}

.modal-content,
.modal-card {
  max-height: unset !important;
  overflow: unset !important;
}

div#otp div {
  flex-wrap: wrap;
  justify-content: center;
}

.rc-anchor-normal .rc-anchor-pt {
  margin: 0 !important;
}

.rc-anchor-normal-footer .rc-anchor-logo-portrait {
  margin: 0 0 0 0px !important;
}

.rc-anchor-logo-portrait {
  margin: 2px 0 0 0 !important;
}

.rc-anchor-light.rc-anchor-normal,
.rc-anchor-light.rc-anchor-compact {
  border: 0 !important;
}

.tooltip .tooltip-arrow {
  bottom: -1px !important;
}

.add-available-box .dropdown-toggle::after {
  display: none;
}

.css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected {
  background-color: #655dd6 !important;
}

.css-knqc4i-MuiDialogActions-root {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center !important;
  justify-content: center !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #655dd6 !important;
}

.add-available-box a.dropdown-item,
button.dropdown-item {
  padding-right: 19px !important;
}

.add-available-box a.dropdown-item label.form-check-label {
  margin-left: 6px;
}

form.verify-box {
  padding: 15px;
}

form.verify-box .card {
  box-shadow: unset;
  border: none;
}

form.verify-box div#otp {
  margin-top: 14px !important;
}

form.verify-box .card div {
  margin-top: 12px;
}

button.btn.btn-warning.px-4.validate {
  background: transparent;
  border: none;
  color: #7269ef;
  font-weight: 600;
  text-decoration: underline;
}

.resent-text {
  font-size: 13px;
  margin-top: 12px;
}

.view-more {
  font-size: 14px;
  color: #7269ef;
  font-weight: 600;
  cursor: pointer;
}

.skeleton-theme-view-more {
  font-size: 14px;
  color: #7269ef;
  font-weight: 600;
  cursor: pointer;
}

.skeleton-theme-view-more h6 {
  font-size: 12px;
  background: #7269ef;
  display: inline;
  padding: 8px 13px;
  color: #7269ef;
  border-radius: 22px;
  cursor: pointer;
}


.clock-main {
  display: flex;
  align-items: center !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  background: #655dd6;
  color: #fff;
  border-radius: 22px;
}

.dropdown-menu.show a .fa {
  position: unset;
  margin-right: 11px;
}

.capabilities-section .card-header {
  display: unset;
}

.view-more h6 {
  font-size: 12px;
  background: #7269ef;
  display: inline;
  padding: 8px 13px;
  color: #fff;
  border-radius: 22px;
  cursor: pointer;
}

.tag-disables {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.dropdown-top .dropdown button img {
  max-width: 45px !important;
  height: 45px !important;
  object-fit: cover !important;
  /* padding: 12px; */
  border: none;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10.5px 14px !important;
  font-size: 14px;
}

li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item {
  font-size: 12px;
  padding: 6px;
}

.inputs input {
  background: #ebebeb !important;
  padding: 12px !important;
}

.inputs input:focus {
  border: none;
}

span.timer {
  background: #7269ef45;
  padding: 3px 22px;
  display: block;
  width: fit-content;
  margin: 0 auto;
  border-radius: 32px;
  font-size: 14px;
  font-weight: 600;
}

i.fa.fa-clock-o.position-unset {
  position: unset;
  margin-right: 6px;
}

.user-text {
  text-transform: unset;
  font-size: 19px;
  color: #000;
  padding: 20px 18px;
  border-radius: 23px 23px 0 0;
  border-bottom: 1px solid #eeeeeed6;
  font-weight: 600;
}

.outer-habit-modal .user-text {
  padding: 0 20px 18px 20px;
}


.profile-card i.fa.fa-phone {
  background: unset;
  color: unset;
  width: unset;
  min-width: unset;
  height: unset;
  min-height: unset;
  display: unset;
  justify-content: unset;
  align-items: unset;
  border-radius: unset;
  cursor: unset;
  margin-right: unset;
}

.profile-card-inner {
  background: #fff;
  /* background-image: url("../assests/images/file.svg"); */
  border-radius: 22px;
  padding: 0 0 32px 0;
  position: relative;
}

.user-text img {
  width: 31px;
  margin-left: 3px;
}

.data-text {
  padding: 0 22px;
}

.icons-left .fade.toast {
  position: absolute;
  top: 40px;
  z-index: 999;
  right: 0;
  min-width: 232px;
}

.icons-left .toast-body {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icons-left .btn-close:after {
  content: "";
  font-size: 17px;
  color: #000 !important;
  font-weight: 700;
  opacity: 1 !important;
  position: absolute;
  top: 28px;
  right: 31px;
}

.icons-left .btn-close {
  background: initial !important;
}

.icons-left .btn-close {
  background: url("../assests/images/close.svg") !important;
  background-repeat: no-repeat !important;
}

.main-hero-section {
  /* background: url("../assests/images/slider-img.png") !important; */
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.icons-left .fa.fa-clone {
  background: #4cae50;
  color: #fff;
  padding: 8px;
  border-radius: 22px;
  font-size: 12px;
  cursor: pointer;
}

.content__container {
  font-weight: 600;
  overflow: hidden;
  height: 52px;
  padding: 0 1px;
}

.content__container__text {
  display: inline;
  float: left;
  margin: 0;
  font-size: 42px;
  line-height: 54px;
  margin-bottom: 16px !important;
}

.content__container__list {
  margin-top: 0 !important;
  padding-left: 0 !important;
  text-align: left !important;
  list-style: none !important;
  -webkit-animation-name: change !important;
  -webkit-animation-duration: 10s !important;
  -webkit-animation-iteration-count: infinite !important;
  animation-name: change !important;
  animation-duration: 10s !important;
  animation-iteration-count: infinite !important;
  margin-left: 0 !important;
}

.content__container__list__item {
  line-height: 46px;
  margin: 0;
  font-size: 41px;
  color: #655dd6;
  font-weight: 700;
}

@-webkit-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@-o-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-o-keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@-moz-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

.main-hero-section {
  padding: 121px 0 34px 0;
}

.main-hero-img {
  position: relative;
}

img.anime-1 {
  position: absolute;
  top: 120px;
  width: 35%;
  right: -50px;
}

img.anime-2 {
  position: absolute;
  top: 70px;
  width: 19%;
}

img.anime-3 {
  position: absolute !important;
  top: -30px;
  width: 48%;
  right: 0;
  z-index: 999 !important;
}

.main-hero-img img {
  z-index: 1;
  position: relative;
}

.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

.heathy-box {
  text-align: left;
  padding: 0 22px;
}

.heathy-box h6 span {
  color: #000;
}

.heathy-box h6 {
  margin: 13px 0;
  color: #8a8a8a;
}

.heathy-box h5 {
  color: #b6b6b6;
  margin-top: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}

.add-btn {
  background: #655dd6;
  padding: 7px;
  display: inline-block;
  border-radius: 5px;
  padding: 4px 10px;
  font-size: 15px;
  color: #fff;
}

.border-bottom-div h4 {
  font-size: 18px;
  font-weight: 700;
}

.inner-health .form-check {
  font-size: 14px;
  margin-top: 8px;
}

.inner-health input {
  margin-top: 6px;
}

.inner-health h6 {
  font-size: 16px;
  color: #000;
}

.inner-health {
  margin-bottom: 10px;
  padding-bottom: 12px;
  background: #f7f7f7;
  padding: 15px;
  border-radius: 12px;
}

.health-outer-box.modal-body {
  padding: 0;
}

.modal-header.heathy-header {
  border: 0;
  padding: 0 0 22px 0;
}

.add-btn:hover {
  color: #fff;
}

.heathy-box h6 img {
  width: 20px;
  margin-right: 12px;
  height: 20px;
}

.healthy-modal {
  position: relative;
}

.profile-card-inner .login-loader {
  position: absolute;
  top: 43%;
  left: 53%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.profile-card-inner .main-login-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #ffffffe5;
  z-index: 0;
  top: 0;
  left: 0;
  border-radius: 12px;
}

#wave1,
#wave2,
#wave3 {
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

#wave1 {
  z-index: -1;
  background-color: var(--bg-color-wave1);
  animation-name: waves;
  animation-delay: 0.1s;
  animation-duration: 2s;
}

#wave2 {
  z-index: -2;
  background-color: var(--bg-color-wave2);
  animation-name: waves;
  animation-delay: 0.15s;
  animation-duration: 2s;
}

#wave3 {
  z-index: -3;
  background-color: var(--bg-color-wave3);
  animation-name: waves;
  animation-delay: 0.19s;
  animation-duration: 2s;
}

@keyframes waves {
  0% {
    transform: scale(1, 1);
    opacity: 0;
    filter: opacity(0);
  }

  50% {
    transform: scale(1.8, 1.8);
    opacity: 0.9;
    filter: opacity(50%);
  }

  100% {
    transform: scale(1.8, 1.8);
    opacity: 0;
    filter: opacity(0);
  }
}

.circle-wave {
  position: absolute;
  z-index: 100;
  right: 50%;
  width: 0px;
  height: 0px;
  border: 1px solid #655dd6;
  opacity: 0;
  border-radius: 50%;
  animation: pulse 5s infinite;
  animation-timing-function: ease-in;
  -webkit-animation-timing-function: ease-in;
}

#c2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

#c3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

#c4 {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

#c5 {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

@keyframes pulse {
  0% {
    width: 0px;
    height: 0px;
    margin-right: -0px;
    top: 175px;
    opacity: 0;
  }

  20% {
    opacity: 0.5;
  }

  100% {
    width: 500px;
    height: 500px;
    margin-right: -250px;
    top: -75px;
    opacity: 0;
  }
}

#circle-hello {
  position: relative;
  z-index: 200;
  margin: 100px auto;
  height: 150px;
  width: 140px;
  border-radius: 50%;
  line-height: 150px;
  color: #fff;
  text-align: center;
  font-family: avenir, arial;
  letter-spacing: 10px;
  padding-left: 10px;
  background: #006087;
  /* Old browsers */
  background: -webkit-linear-gradient(#006087 0%, #3c003f 100%);
  background: -moz-linear-gradient(#006087 0%, #3c003f 100%);
  background: -o-linear-gradient(#006087 0%, #3c003f 100%);
  background: linear-gradient(#006087 0%, #3c003f 100%);
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#006087', endColorstr='#3c003f', GradientType=0);
  /* IE6-9 */
}

#container-main {
  position: absolute;
  right: 0;
  bottom: 212px;
  z-index: 0;
}

.appointment-text {
  color: #000000;
  font-size: 14px;
  display: inline-block;
  text-align: left;
  cursor: pointer;
}

.problem-left-box {
  border-radius: 10px;
  border: 1px solid rgba(114, 105, 239, 0.26);
  background: rgba(114, 105, 239, 0.08);
  padding: 17px;
}

.details-user img {
  width: unset !important;
  height: unset !important;
}

.user-medication-area {
  padding: 80px 0;
}

.border-bottom-div {
  border-bottom: 1px solid #0000001A;
  padding-bottom: 20px;
  padding-top: 14px;
}

.save-btn {
  text-align: right;
  margin-top: 14px;
}

.recommend-box span {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFFEFE;
  padding: 3px 12px;
  font-size: 13px;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 7px;
  cursor: pointer;
}

.medication-table .table>:not(:last-child)>:last-child>* {
  border-bottom-color: currentColor;
  background: #F7F6FE;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  border: none;
}

.medication-table table td {
  font-size: 13px;
  width: 16%;
}

.doctor-details-box h6 {
  margin-bottom: 7px;
  font-size: 14px;
  /* font-weight: 600; */
}

.border-bottom-div.last-border {
  border-bottom: 0;
}

.save-btn button {
  font-size: 18px;
  padding: 7px 43px;
}

.input-box .form-label {
  font-size: 13px;
  color: #000;
  font-weight: 700;
  margin-top: 10px;
  padding: 0;
  margin-bottom: 0;
}

.input-box .form-control {
  padding: 9px;
  font-size: 15px;
}

.medication-modal .modal-dialog {
  max-width: 881px !important;
}

.medication-table img {
  cursor: pointer;
}

.problem-box .add-btn {
  padding: 7px 10px;
  border-radius: 9px;
}

.problem-left-box p {
  font-size: 14px;
}

.date-box .add-btn {
  padding: 1px 10px;
  font-size: 12px;
}

.problem-left-box h5 {
  font-size: 16px;
  font-weight: 700;
}

.profile-card-inner.doctor-user {
  border: 1px solid #635bd2;
}

.doctor-user .user-text {
  font-size: 15px;
}

.doctor-user .user-text.mb-3 img {
  width: 27px;
  margin-left: 11px;
}

.doctor-user .heathy-box h6 {
  font-size: 13px;
}

.doctor-user .heathy-box h6 img {
  width: 15px;
  height: 15px;
}

.no-test-here {
  text-align: center;
  opacity: 0.4;
  padding: 9px;
}

.recommend-box .active {
  background: #7f79f0 !important;
  color: #fff !important;
  cursor: pointer;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
}

.key-point-box.history-box {
  /* max-height: 103px; */
  margin-bottom: 10px;
  overflow: auto;
  padding: 15px 0 6px 15px;
}

.disableBtn {
  opacity: 0.7;
  cursor: not-allowed !important;
  filter: grayscale(100%);
}

.professional-tools i {
  opacity: 0.8;
}

.download_box {
  background: #7269EF;
  color: #fff;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 5px;
  /* filter: brightness(0) invert(1); */
  /* margin-left: 9px; */
}

.download_box:hover {
  color: #fff;
}

.white_space {
  white-space: pre-line;
}

.upper-appointment {
  display: flex;
  margin-top: 12px;
}

.upper-appointment .form-check-inline {
  margin-right: 12px !important;
}

.toast-body input {
  border: none;
  background: transparent;
  padding: 0;
}

.fade.toast.show p img {
  width: 17px;
  margin-right: 10px;
  margin-top: -4px;
}

.fade.toast.show p {
  text-align: left;
  padding: 0 10px;
  margin-top: 6px;
  font-weight: 700;
}

.medication-table {
  max-height: 370px;
  height: 100%;
  overflow: auto;
}

.recommend-box-upper .recommend-box {
  max-height: 132px;
  height: 100%;
  overflow: auto;
}

.white_space {
  white-space: pre-line;
}

/* .health-inner-main-box {
  background: #fff;
  padding: 8px;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%);
  border-radius: 23px;
} */
.health-left-area h3 {
  font-size: 28px;
  background: #7269ef;
  display: inline-block;
  color: #fff;
  padding: 11px 50px;
  transform: rotate(6.2248rad);
  border-radius: 52px;
  margin-top: 23px;
  text-transform: uppercase;
}

.health-section {
  padding: 82px 0;
}

.health-left-area h2 {
  font-weight: 600;
  margin-top: 12px;
}

.health-left-area {
  text-align: center;
  padding: 52px 0;
}

.health-right-box {
  padding: 31px 23px 12px 29px;
  border-left: 1px solid #7269ef70;
}

.health-inner-main-box {
  border: 2px solid #7269ef52;
  border-radius: 31px;
  background-color: #fff;
}

.health-right-box h5 {
  display: flex;
  margin-bottom: 28px;
}

.health-right-box h5 img {
  width: 21px;
  height: 21px;
  margin-right: 13px;
}

.health-right-box h5 p {
  font-size: 17px;
}

.set_margin {
  margin-right: 10px;
}

.no-history-box {
  padding: 5px;
  opacity: 0.4;
  text-align: center;
}

.upper-user-information h5 {
  padding: 20px 0 14px 33px;
}

.upper-user-information .profile-card {
  border-radius: unset !important;
  border: none;
  background: #fff;
  padding: 0 21px;
  margin-top: 11px;
}

.upper-user-information .profile-card img {
  width: 15px;
  height: 15px;
}

.toast-header .btn-close {
  margin-right: 0 !important;
}

.button-section .add-btn {
  margin-right: 6px;
  height: 36px;
  width: 36px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button-section {
  display: flex;
}

.button-section .add-btn.share-b {
  background: #b0e8f7;
  color: #000;
  cursor: pointer;
}

.button-section .add-btn.share-btn {
  background: #b0e8f7;
  color: #000;
}

.history-height {
  max-height: 322px;
  height: 100%;
  overflow: auto;
}

.features-section.common-padding .atorny-card {
  height: 390px;
  margin-bottom: 21px;
}

.atroy-inner-box b {
  text-transform: uppercase;
}

.key-point-box.history-box {
  margin-bottom: 10px;
  overflow: auto;
  box-shadow: unset;
  border-bottom: 1px solid #00000014;
  border-radius: 0 !important;
  overflow: unset;
}

.key-point-box.history-box .date-box.d-flex.justify-content-between.mb-3 {
  margin-bottom: 0 !important;
}

footer {
  padding-top: 14px !important;
}

.user-medication-area .back-btn-box a {
  margin-top: 3px;
}

.border-bottom-div.first-child {
  border-top: 1px solid #0000001A;
}

.user-medication-form {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
  padding: 32px;
}

.dropdown-top.logout-btn-mobile button {
  background: transparent !important;
}

.prompt-box.right-chat-box-dropdown.dropdown-spacing .dropdown-top .btn-check:checked+.btn,
.prompt-box.right-chat-box-dropdown.dropdown-spacing .btn.active,
.prompt-box.right-chat-box-dropdown.dropdown-spacing .btn.show,
.prompt-box.right-chat-box-dropdown.dropdown-spacing .btn:first-child:active,
.prompt-box.right-chat-box-dropdown.dropdown-spacing :not(.btn-check)+.btn:active {
  background: #7269ef !important;
}

.prompt-heading {
  background: #7269ef1c !important;
  border: none !important;
  border-radius: 10px !important;
}

.left-chat-box p img {
  width: 19px;
  margin-right: 10px;
}

.pdf-view-modal .modal-dialog {
  max-width: 1172px;
}

.pdf-view-inner iframe {
  vertical-align: middle;
  width: 100%;
  text-align: center;
  height: 85vh;
  object-fit: cover;
}

p .pdf-view-inner {
  position: relative;
}

.pdf-view-modal .modal-content {
  padding: 0 0;
}

.pdf-view-modal .add-btn {
  background: #655dd6;
  padding: 7px;
  display: inline-block;
  border-radius: 5px;
  padding: 4px 10px;
  font-size: 16px;
  border-radius: 142px;
  color: #fff;
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  /* float: right; */
  right: 9px;
}

.available-btn {
  justify-content: flex-end;
}

.dropdown-top.menu-profile.logout-btn .dropdown-toggle::after {
  right: 0;
}

.suggestion-tags.siggestion-inner-box {
  background: #fff;
  border: 1px solid #00000024;
  border-radius: 2px;
  height: 252px;
  overflow-y: auto;
  /* padding: 5px; */
  position: absolute;
  width: 34%;
}

.main-check-box .form-check-input {
  border: 1px solid #0000006e !important;
}

.table-hover>tbody>tr:hover>*,
.table-hover>tbody>tr:hover {
  --bs-table-color-state: unset !important;
  --bs-table-bg-state: #fff !important;
}

.table-hover>tbody>tr:hover {
  /* --bs-table-accent-bg: var(--bs-table-hover-bg); */
  color: unset !important;
  background-color: #fff !important;
}

.suggestion-tags .tags {
  background: transparent;
  border: none;
  border-bottom: 1px solid #00000017;
  border-radius: 0;
  box-shadow: none;
  font-size: 12px;
  padding: 5px 9px;
  width: 100%;
}

.button-section {
  display: flex;
  align-items: start;
}

.key-point-box.history-box.required-box {
  overflow: unset;
  max-height: unset;
}

.key-point-box.history-box.required-box .tags-box-main {
  margin-top: 12px;
}

.no-data-found {
  opacity: 0.3;
  text-align: center;
  padding: 94px;
}

.date-inner .fa.fa-calendar {
  top: 11px;
}

.w-34 {
  width: 36% !important;
}

.w-20 {
  width: 20% !important;
}

.main-hero-img video {
  width: 100%;
}

.get-in-touch .pulse1 {
  width: 15px;
  height: 15px;
  border-radius: 50px;
  display: inline-block;
  animation: 1.5s infinite pulse1;
  border: 2px solid #fff;
  margin-right: 12px;
}

.content .btn.btn-primary {
  padding: 16px 27px;
  display: flex;
  align-items: center;
}

@keyframes pulse1 {
  0% {
    box-shadow: 0 0 0 0 #fbb32f
  }

  100% {
    box-shadow: 0 0 0 17px #fbb32f01
  }
}

.call-action .form-control {
  padding: 9px !important;
}

.react-tel-input .form-control {
  border: 1px solid #dee2e6 !important;
}

.pdf-view-modal .btn-close:after {
  top: 10px;
}

.slot-changes a {
  font-size: 14px;
  font-weight: 600;
  border-radius: 11px;
  cursor: pointer;
  margin-left: 12px;
  color: #655dd6 !important;
  text-decoration: underline !important;
}

.slot-changes {
  display: flex;
  align-items: center;
}

.slot-changes .fa {
  position: unset;
  margin-right: 8px;
}

.select-slot {
  margin-top: 13px;
}

.react-tel-input .form-control {
  border: 1px solid #dee2e6 !important;
  padding: 9px 47px !important;
}

/* .tooltip.show {
  z-index: 0;
} */

.recommend-span-box {
  border: none !important;
  padding: 0 !important;
  display: block !important;
}

.recommend-span-box span {
  border: none !important;
  padding: 0 !important;
  width: 23%;
  margin-bottom: 0 !important;
}

.chat-module {
  display: flex;
  align-items: center;
}

.chatbox-footer button#dropdown-basic {
  background: none;
  border: none;
  padding: 0;
}

.chatbox-footer button#dropdown-basic i {
  color: #000;
}

button#dropdown-basic img {
  width: 22px;
  min-width: 22px;
  margin-top: -22px;
}

.chatbox-footer .dropdown-menu.show {
  position: absolute;
  top: -104px !important;
  border: 1px solid #0000001a;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  width: fit-content !important;
  min-width: fit-content !important;
}

.chatbox-footer a.dropdown-item i {
  font-size: 14px;
  margin-right: 5px !important;
}

.attachment-upload input {
  display: block;
}

.attachment-upload {
  position: relative;
}

.attachment-upload input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.uploaded-image-area img {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 5px;
}

.uploaded-image-area i {
  background: red;
  color: #fff;
  font-size: 13px;
  width: 15px;
  min-width: 15px;
  height: 15px;
  min-height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: -4px;
  right: -5px;
  cursor: pointer;
}

.uploaded-image-area {
  position: relative;
  top: 0;
  left: 0;
  width: fit-content;
}

.right-chat-box img {
  border-radius: 6px;
  height: 100px;
  min-height: 100px;
  min-width: 100px;
  object-fit: cover;
  width: 100px;
  border: 1px solid #00000045;
}

.essential-section {
  padding: 50px 0;
}

.subheadings-main {
  font-size: 34px;
  font-weight: 800;
}

.indicate-text {
  color: #7269ef;
}

.essential-box {
  padding: 50px 30px;
}

.essential-box h2.subheadings-main {
  font-size: 52px;
  letter-spacing: -3px;
  line-height: 52px;
  margin-bottom: 28px;
}

.icons-boxes span {
  margin-right: 23px;
}

.icons-boxes span i {
  font-size: 15px;
  margin: 0 4px 0 0;
  color: #7269ef;
}

.essential-box-img {
  padding: 60px 8px;
  border-radius: 22px;
  background: linear-gradient(316deg, #b0e8f72b 25.8%, rgb(114 105 239 / 22%) 90.26%);
}

.essential-box-img .swiper-button-prev:after,
.essential-box-img .swiper-button-next:after {
  font-size: 23px;
  color: #655dd6;
}

.essential-box-img.under-right-img {
  background: transparent;
  padding: 72px;
}

.essential-box-img .swiper-slide img {
  padding: 0 52px;
}

@media screen and (max-width: 1600px) {
  .registration-area {
    /* background: url(http://localhost:3000/static/media/hero-banner-small.88b88888c4f45e1db170.png); */
    background-repeat: no-repeat;
    height: 100vh;
    background-position: right;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content__container {
    height: 51px;
  }

  #container-main {
    right: 190px;
  }
}

.famil-box-info .user-text {
  text-transform: unset;
  font-size: 19px;
  color: #000;
  padding: unset;
  border-radius: unset;
  border-bottom: unset;
  font-weight: 600;
  margin-bottom: 0 !important;
}

.famil-box-info {
  padding: 18px 20px;
  border-radius: 23px 23px 0 0;
  border-bottom: 1px solid #eeeeeed6;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cancer-outer-box .mid-chat-box form {
  margin: 13px 20px 12px;
}

.left-chat-boxes.cancer-ai-box h5 {
  padding: 0 21px;
}

.promt-section-iiner {
  /* background: url("../assests/images/feature.jpg") no-repeat; */
  padding: 25px;
  background-size: cover;
  background-position: center top;
  margin-top: 22px;
}

.prompt-box-modal .modal-dialog {
  max-width: 924px;
}

.promt-main-box {
  border-radius: 7.73px;
  background: #f1f1f1;
  margin-bottom: 12px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 15px;
  cursor: pointer;
}

.promt-main-box img {
  width: 37px;
  height: 100%;
}

.promt-main-box h4 {
  font-weight: 600;
  margin-left: 16px;
  font-size: 17px;
}

.family-box-section .modal-dialog {
  max-width: 952px;
}

.famil-box-info a {
  cursor: pointer;
  color: #7269ef;
  font-size: 14px;
}

.upload-family-img {
  text-align: center;
  position: relative;
  padding: 52px 0;
}

.upload-family-img input {
  position: absolute;
  top: 60px;
  opacity: 0;
  left: 72px;
  width: 130px;
  cursor: pointer !important;
  height: 120px;
  background: #000;
}

.upload-family-img.profile-upload input {
  left: 40%;
}

.header-family .family-content {
  margin-left: 12px;
}

.header-family {
  display: flex;
  align-items: center;
}

.family-box-body .accordion-button {
  background: #fff !important;
  border: none !important;
  border-top-left-radius: 0 !important;
}

.family-box-body .accordion-button {
  background: #fff !important;
  border: none !important;
  border-top-left-radius: 0 !important;
  box-shadow: unset !important;
  border-bottom: 1px solid #0000001c !important;
  border-radius: 0 !important;
}

.family-box-body .accordion-item {
  border: none;
}

.family-content p {
  font-size: 12px;
  margin-top: 4px;
}

.family-content h5 {
  font-size: 16px;
  font-weight: 600;
}

.header-family img {
  width: 52px;
  height: 52px;
  min-width: 52px;
  object-fit: cover;
  border-radius: 52px;
  /* border: 1px solid #0000004d; */
}

.upper-user-information h5 {
  padding: 15px 0 7px 33px;
}

.upper-user-information {
  border-top: 1px solid #0000000f;
  border-bottom: 1px solid #0000000f;
  margin-top: 14px;
}

.inner-chatbot-main {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.active-tabs button {
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.16);
  font-size: 13px;
  border-radius: 10px;
  margin-left: 2px;
}

button.someone-text.btn {
  box-shadow: unset !important;
  background: #fff !important;
  color: #000 !important;
  font-weight: 500;
}

button.someone-text.btn:hover {
  color: #000 !important;
}

.rounded-checkbox {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #00000045;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.rounded-checkbox:checked {
  appearance: auto;
  clip-path: circle(50% at 50% 50%);
  accent-color: #7269ef;
}

.family-inner-box div h5 {
  font-size: 17px;
  font-weight: 600;
  margin-left: 8px;
}

.family-inner-box div {
  display: flex;
  align-items: center;
}

.family-inner-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #0000001f;
}

.family-inner-box:last-child {
  border-bottom: 0;
}

/* .last-box{
  margin-bottom: 0 ;
} */
.download_box.calender-box-icon .fa {
  position: unset;
}

.download_box.calender-box-icon {
  margin-right: 7px;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: #655dd6;
  width: fit-content;
  font-size: 13px;
  overflow: hidden !important;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #655dd6 !important;
}

.family-inner-box img {
  max-width: 34px;
  height: 34px;
  border-radius: 52px;
  width: 34px;
  object-fit: cover;
}

.family-box-section select#formBasicEmail {
  height: unset;
}

.upload-family-img img {
  width: 130px;
  height: 130px;
  border-radius: 122px;
  object-fit: cover;
}

.active-tabs .btn.btn-primary:active {
  background: #7269ef !important;
}

.active-tabs button.someone-text.btn.btn-primary {
  background: transparent !important;
}

.left-chat-boxes.cancer-ai-box {
  border: none;
}

.add-chat-btn .btn.btn-unset:active {
  background: #7269ef !important;
}

.no-data-found-family {
  opacity: 0.6;
  text-align: center;
  padding: 42px 0 0 0;
}

.no-data-found-family img {
  margin-bottom: 10px;
}

.single-family-popup {
  text-align: center;
}

.record-menu img {
  width: 22px;
}

.record-menu {
  border: 1px solid;
  padding: 4px 7px;
  color: #000;
  display: inline-block;
  border-radius: 56px;
  font-size: 14px;
  margin-top: 5px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  cursor: pointer;
}

.record-menu:hover {
  color: #000;
}

.recorded-content {
  text-align: center;
}

.record-icon {
  width: 30%;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
  border-radius: 72px;
}

.pause-icon {
  width: 13%;
  height: 100%;
  cursor: pointer;
  margin-right: 6px;
}

.recorded-animation {
  min-height: 72px;
  border-radius: 12px;
  padding: 12px 20px;
  font-size: 13px;
}

.view-menu {
  background: #ffffff;
  padding: 19px;
  border-radius: 56px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  cursor: pointer;
  color: #000;
  border: 1px solid;
  margin-top: 6px;
}

.recorde-outer {
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.view-menu .fa {
  color: #000;
}

.view-menu:hover {
  border: 1px solid #000 !important;
}

section.generate-section {
  padding: 82px 0;
  background: #f8fafb;
}

.generate-history-box {
  box-shadow: 0px 2px 4px rgba(169, 194, 209, .05), 0px 12px 16px rgba(169, 194, 209, .1);
  padding: 22px;
  background: #fff;
  border-radius: 12px;
  max-height: 746px;
  overflow: auto;
  height: 100%;
}

.right-generate-box {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
}

.right-generate-box p {
  font-size: 13px;
}

.inner-genrate-box h4 {
  font-size: 16px;
  overflow: hidden !important;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  font-weight: 400;
}

.inner-genrate-box:last-child {
  border-bottom: none;
}

.transcription-box h6 {
  font-size: 17px;
  text-transform: uppercase;
  /* margin-bottom: 11px; */
  font-weight: 600;
}

.inner-transcription-box {
  background: #f8fafb;
  padding: 21px;
  border-radius: 12px;
  max-height: 241px;
  overflow: auto;
  margin-bottom: 12px;
}

.inner-transcription-box p {
  font-size: 14px;
  /* font-weight: 600; */
}

.finalize-btn.btn.btn-unset {
  /* border: 1px solid #7269ef; */
  color: #ffffff !important;
  background: #e3e1fc;
  /* margin-top: 12px; */
  border-radius: 72px;
  color: #000 !important;
}

.finalize-btn.btn.btn.btn-unset img {
  min-width: 21px;
  width: 21px;
}

.recorded-content .btn-disabled {
  background: unset !important;
  cursor: not-allowed !important;
  color: #fff;
  opacity: 0.2 !important;
}

.family-box-section .form-control,
.family-box-section .form-select {
  padding: 11px !important;
  border-radius: 8px !important;
  border: 1px solid #dbd5d5 !important;
}

.family-box-section .react-tel-input .form-control {
  border: 1px solid #dbd5d5 !important;
  padding: 24px 47px !important;
  width: 100%;
  opacity: 0.7;
}

.family-box-section .form-control::placeholder {
  color: #000;
  opacity: 0.3;
  /* Firefox */
}

.family-box-section .form-label {
  font-weight: 500;
}

.family-box-section .modal-footer {
  border-top: unset;
}

.family-box-section .form-control::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #000;
}

.inner-transcription-box.text-area-box {
  padding: 5px 21px;
  min-height: 302px;
}

.inner-genrate-box {
  position: relative;
}

.click-div {
  padding: 9px 0;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  padding: 14px 41px 14px 11px;
  margin-bottom: 8px;
  border-radius: 13px;
}

.click-div.active {
  background: #bebbe142;
  border-radius: 10px;
  border-bottom: 0;
  color: #000 !important;
  position: relative;
}

.inner-genrate-box .fa {
  position: absolute;
  top: 16px;
  right: 21px;
}

button.paste-btn.btn.btn-primary {
  background: #5f55df !important;
  margin-left: 8px;
}

/* .history-btn-area {
  margin-top: 11px;
} */
.inner-summerized-area.inner-summerized-area-main {
  margin-bottom: 24px;
}

.left-gnerate .no-data-box {
  text-align: center;
  padding: 42px;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.no-data-box {
  text-align: center;
  padding: 72px;
  opacity: 0.5;
}

.no-data-box h5 {
  font-size: 17px;
  margin-top: 15px;
}

.recorded-content textarea {
  margin-top: 22px !important;
}

.date-icon {
  position: relative;
}

.date-icon .fa.fa-calendar {
  position: absolute;
  top: 49px;
  right: 15px;
  pointer-events: none;
  cursor: pointer;
  background: #fff;
  padding: 3px;
}

.date-icon.main .fa.fa-calendar {
  position: absolute;
  top: 43px;
  right: 15px;
  pointer-events: none;
  cursor: pointer;
  background: #fff;
}

.profile-card-inner.outer-profile-main.mt-3 {
  margin-top: -22px !important;
}

.record-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.record-footer .finalize-btn.btn.btn-unset {
  margin-right: 10px;
}

textarea #chat-response-id {
  height: 200px;
  overflow-y: scroll;
}

.inner-summerized-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.record-animation-hide {
  display: none;
}

.availability-box select {
  color: #000;
}

.firefox-browser-modal .sound-wave {
  width: 100% !important;
  margin-top: 22px;
  z-index: 0;
  position: relative;
}

.inner-pause-icon img {
  width: 25%;
}

.date-icon input::-webkit-date-and-time-value {
  text-align: left !important;
}

.redesign-prompt-library .accordion {
  border-radius: 0;
}

.redesign-prompt-library .accordion-button::after {
  right: 18px;
  bottom: 18px;
  background-image: unset;
  content: "\f0dd ";
  position: absolute;
  font-family: 'FontAwesome';
}

.redesign-prompt-library .accordion-button:not(.collapsed)::after {
  bottom: 15px;
}

.redesign-prompt-library .accordion-button:focus {
  border-color: none !important;
  box-shadow: unset !important;
}

.redesign-prompt-library .accordion-body {
  background: #ededed;
  margin: 5px 10px 13px 10px;
  font-size: 14px;
  padding: 9px 18px;
  border-radius: 11px;
  cursor: pointer;
}

.recorded-content img {
  z-index: 9;
  position: relative;
}

.redesign-prompt-library .accordion-button {
  background: #7269ef;
  color: #fff;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  border-radius: 12px 0 12px 0;
}

.redesign-prompt-library .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 0 !important;
}

.redesign-prompt-library .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 12px !important;
  border-bottom-left-radius: 0 !important;
}

.redesign-prompt-library .accordion-item {
  border: none;
}

.left-chat-boxes.cancer-ai-box.cancer-outer-main-box {
  box-shadow: unset;
  padding: 0;
}

.family-inner-box.rounded-family-img img {
  width: 55px;
  max-width: 55px;
  height: 55px;
}

.record-menu.mobile-menu-recorded,
.view-menu.mobile-menu-recorded {
  display: none;
}

.record-menu.mobile-menu-recorded {
  padding: 8px;
  border-radius: 11px;
  font-size: 11px;
}

.view-menu.mobile-menu-recorded {
  padding: 8px;
  border-radius: 12px;
  width: unset;
  height: unset;
  display: inline-block;
  font-size: 11px;
}

.view-menu.mobile-menu-recorded .fa {
  margin-right: 7px;
}

.record-menu.mobile-menu-recorded img {
  margin-right: 7px;
}

.view-menu.mobile-menu-recorded {
  display: none;
}

.sound-wave-line-hide {
  display: none;
}

input,
textarea {
  -webkit-user-select: text;
}

/* dashboard css start */
.text-orange {
  color: #7269EF;
}

.time-period {
  max-width: 152px;
}

.card-shadow {
  box-shadow: -1rgba(1, 41, 255, 0.05);
  box-shadow: 0px 4px 44px -1px rgba(1, 41, 255, 0.05);
  background: #fff;
  padding: 31px;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.card-shadows {
  box-shadow: -1rgba(1, 41, 255, 0.05);
  box-shadow: 0px 4px 44px -1px rgba(1, 41, 255, 0.05);
  background: #fff;
  padding: 31px;
  border-radius: 20px;
}

.card-content {
  margin: 0 12px;
  text-align: left;
}

.f-700 {
  font-weight: 700;
}

.text-blue {
  color: #FF7600;
}

.card-contents .btn-primary {
  background: transparent;
  color: #000;
  border: none;
}

.card-contents .btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
  color: #000;
  background-color: #ffffff;
  border-color: transparent;
}

.card-contents .btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.card-contents .btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

/* dashboard css end */



.list-view-box {
  display: flex;
}

.list-view-box .list-inner {
  background: #fff;
  width: 44px;
  height: 42px;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 10px;
  cursor: pointer;
}

.outer-dashboard-section .appointment-area ul li.active {
  background: #7269ef;
  color: #fff !important;
}

.outer-dashboard-section .appointment-area ul {
  border: 2px solid #7269ef;
}

.outer-dashboard-section .appointment-area ul li {
  color: #7269ef;
}

.outer-dashboard-section .appointment-area {
  justify-content: flex-end;
}

.outer-dashboard-section {
  padding: 18px 0;
}

.appointment-list-view h4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.active.list-inner .dark-view-no-active {
  display: none;
}

.active.list-inner .dark-view {
  display: block;
}

.list-inner .dark-view {
  display: none;
}

.calender-boxes .sc-imWYAI.oKmCK {
  width: 100%;
  padding: 0 22px;
  border: 0;
}

.calender-boxes .kgUbNA {
  grid-gap: 52px;
}

.calender-boxes .cHDjOk,
.calender-boxe .hEJIAY {
  width: 44px !important;
  height: 44px !important;
  font-size: 18px !important;
}

.calender-boxes .kYbjQz {
  margin-top: 20px;
  gap: 25px;
}

.calender-boxes .hEJIAY {
  width: 34px;
  height: 34px;
  font-size: 19px;
}

.calender-boxes .sc-cwHptR.dUVVLu {
  background: #7269ef;
  padding: 15px;
  border-radius: 12px;
  color: #fff;
  box-shadow: 0px 3.32055px 22.41368px 0px rgba(0, 0, 0, 0.15);
}

.calender-boxes .gJDhkC svg {
  fill: #fff;
}

.calender-boxes .cHDjOk[aria-pressed='true'] {
  background: #7269ef;
  color: #fff;
}

.calender-boxes .bAiWfp {
  font-size: 18px;
}

.doctor-left-pannel {
  position: absolute;
  background: #000;
  width: fit-content;
  top: 181px;
  left: 22px;
  padding: 22px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.13);
}

p.duration {
  text-align: right;
  font-size: 12px;
  color: #0000009c;
  font-weight: 500;
  position: unset !important;
  background: unset !important;
  color: #000 !important;
  display: unset !important;
  padding: 0 !important;
}

.call-history.active .nav-item a {
  color: #fff !important;
}

p.duration span {
  color: #000;
  margin-right: 6px;
  font-weight: 600;
}

.appointment-boxes.report-insight-area {
  padding-top: 90px;
}

.appointment-boxes .appointment-area h2 {
  font-size: 22px;
  font-weight: 700;
}

.doctor-left-pannel .sidebar-icon {
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.13);
  background: #fff;
  border-radius: 13px;
  width: 48px;
  display: inline-block;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.doctor-left-pannel ul li a {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #000;
}

.doctor-left-pannel ul li {
  margin-bottom: 17px;
}

.doctor-left-pannel .sidebar-icon img {
  width: 21px;
  height: auto;
}

.doctor-left-pannel li.active a {
  color: #7269ef;
  font-weight: 600;
}

.width-wise-dashboard {
  padding: 0 312px;
}

.doctor-left-pannel li.active a span.sidebar-icon {
  border: 1px solid;
}

.add-report-top-btn {
  display: flex;
  justify-content: end;
}

.pdf-left-area img {
  width: 85px;
  height: 85px;
  min-width: 85px;
  min-height: 85px;
  outline: none;
  border-radius: unset;
}

.report-insight-left-area {
  display: flex;
  align-items: flex-start;
}

.pdf-left-area {
  margin-right: 12px;
}

.report-insight-content {
  display: flex;
  align-items: flex-start;
}

.pdf-content-right-area p {
  white-space: unset !important;
}

.pdf-content-right-area h3 {
  font-size: 18px;
  font-weight: 800;
  color: #000;
}

.report-left-area {
  padding-right: 100px;
}

.report-right-area {
  text-align: right;
}

.report-area-bootom-btn button {
  padding: 9px 17px;
  border-radius: 22px;
  color: #fff !important;
}

button.view-btn.btn.btn-unset {
  background: #7269ef;
}

button.delete-btn.btn.btn-unset {
  background: #dc1d00;
}

.no-call-histroy img {
  width: 30%;
}

.no-call-histroy {
  text-align: center;
  padding: 52px;
}

.no-call-histroy p {
  font-size: 16px !important;
  color: #000;
}

.report-area-bootom-btn button {
  border-radius: 62px;
  color: #fff;
  margin: 12px 0 0 5px;
  font-size: 14px;
  font-weight: 600;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.add-report-top-btn button {
  border-radius: 140px;
  font-weight: 700;
  font-size: 15px;
  padding: 5px 18px;
  background: #fff !important;
  color: #7269ef !important;
}

.generated-report-area {
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  padding: 32px;
  margin: 15px 0 50px 0;
  border-radius: 10px;
  border: 1px solid rgba(138, 138, 138, 0.3);
  background: #fff;
}

.generated-report-area h6 {
  color: #000;
  font-size: 19px;
  margin-bottom: 25px;
  font-weight: 700;

}

.generated-report-inner-area p {
  font-size: 16px;
  color: #4d4d4d;
}

.generated-report-inner-area h5 {
  font-size: 16px;
  font-weight: 600;
  width: 100px;
  min-width: 100px;
}

.generated-report-area button {
  background: #7269ef;
  color: #fff !important;
  font-weight: 600;
  border-radius: 90px;
  padding: 5px 20px;
  font-size: 15px;
  display: flex;
  margin: 30px 0 0 auto;
}

.generated-report-inner-area {
  display: flex;
  align-items: flex-start;
  margin: 8px 0;
}

.pdf-content-right-area p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.bottom-pdf-content {
  display: none;
}

.pdf-content-right-area.bottom-pdf-content.appointment_fullcontent {
  margin-top: -53px !important;
  margin-left: 111px;
}

.common-spacing-area {
  padding-left: 0;
  padding-top: 24px;
}

.fade.video-history-popup-box.modal.show .modal-dialog.modal-lg {
  --bs-modal-width: 1300px !important;
}


.video-history-popup-box.modal .active.nav-item span {
  color: #fff;
}

.video-history-popup-box.modal .active.nav-item a {
  color: #fff;
}

.video-history-popup-box.modal .active.nav-item {
  background: #7269ef;
}

.video-history-popup-box.modal .nav-item {
  background: #dbdbdb;
  font-size: 14px;
  position: relative;
  height: 69px;
}

i.fa.fa-phone.disable-call {
  background: #808080a8;
  cursor: not-allowed !important;
}

.video-history-popup-box.modal .nav-item p {
  position: absolute;
  top: -9px;
  right: 5px;
  background: #00bb00;
  color: #fff;
  font-size: 12px;
  padding: 0 7px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: 600;
}

.call-history {
  background: #dbdbdb;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 4px 8px;
  margin: 8px 0;
  position: relative;
  cursor: pointer;
}

.call-history .nav-item a {
  color: #000 !important;
}

.video-history-popup-box.modal .nav-item a {
  color: #000;
  font-weight: 700;
  background: none !important;
  padding: 9px 6px;
}

.video-history-popup-box.modal .nav-item span {
  font-size: 12px;
  color: #000;
}

.video-history-popup-box.modal .col-sm-10 {
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  padding: 17px;
  border: 1px solid #7269ef;
  border-radius: 22px;
}

.content-tab-bottom.tab-content {
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  padding: 17px 3px 17px 17px;
  border: 1px solid #e3e3e3;
  border-radius: 20px;
  background: #fff;
}

.call-history-tab-left {
  height: 480px;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  padding: 17px 3px 17px 17px;
  border: 1px solid #e3e3e3;
  border-radius: 22px;
  overflow: auto;
  background: #fff;
}

.calender-view-section.calender-view-box-section {
  padding-top: 39px;
}

.calender-boxes .react-calendar {
  width: 100%;
  border-radius: 19px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.13);
  background: #fff;
  padding: 5px;
}

.calender-boxes .react-calendar button {
  width: 47px;
  height: 47px;
  border-radius: 12px;
  position: relative;
  overflow: unset !important;
}

.calender-boxes .react-calendar__tile--active {
  background: #7269ef !important;
  color: white;
  border-radius: 11px !important;
}

.calender-boxes abbr {
  text-decoration: none !important;
}

.no-appoitment-schedule {
  text-align: center;
  opacity: 0.3;
  padding: 62px;
}

.calender-boxes .react-calendar__tile--now {
  background: #7269ef5c;
}

.no-appoitment-schedule p {
  font-size: 20px;
  margin-top: 18px;
  margin-left: 32px;
  font-weight: 700;
}

.calender-list-view-section .doctor-side .icons-left {
  margin-top: 11px;
}

.calender-list-view-section .doctor-side .appointment_fullcontent {
  margin-top: -82px !important;
}

.calender-list-view-section .appointments_box.open .appointment_fullcontent {
  margin-right: 110px;
}

.report-insight-section .appointments_box.request_appointments_box.doctor-side.toggle_past.width-wise-dashboard-left.open .report-area-bootom-btn {
  margin-top: 81px;
}

.report-insight-section .doctor-side .icons-left {
  margin-top: 0;
}

.report-upload-file {
  text-align: center;
  position: relative;
}

.report-upload-file img {
  width: 33%;
  height: auto;
  cursor: pointer;
}

.report-upload-file input {
  position: absolute;
  top: 0;
  left: 36%;
  width: 30%;
  height: 142px;
  cursor: pointer;
  opacity: 0;
}

.red-text {
  color: red;
}

.flex-direction-main {
  flex-direction: column;
}

.flex-direction-main p {
  font-size: 14px;
  max-height: 191px;
  overflow: auto;
  height: 100%;
}

.pricing-area.report-insight-section.width-wise-dashboard {
  padding-top: 23px;
}

.appointment-text-main {
  position: absolute;
  font-size: 13px;
  width: 25px;
  height: 25px;
  color: #fff;
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -9px;
  left: 1px;
  background: #443d9b;
  font-weight: 700;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.13);
  z-index: 99;
}

.main-login-loader-reltaive {
  position: absolute;
  border-radius: 12px;
  background: #ffffffc4;
  height: 100%;
  width: 100%;
}

.sidebar-li {
  cursor: pointer;
}

.inner-dashboard-section {
  padding: 0 22px 0 290px;
}

.card-contents.inner-card-content-main.w-100.align-items-cente.justify-content-between {
  display: flex;
}

.inner-dashboard-section .card-shadow img {
  width: 26%;
}

.flex-direction-main .file_error {
  color: red;
  font-size: 14px;
  display: inline-block;
  margin-top: 6px;
}

h2#swal2-title {
  font-size: 21px;
}

.call-history.active {
  background: #7f79f0;
}

.call-history.active .nav-item {
  background: #7f79f0;
}

.call-history.active .nav-item span {
  color: #fff !important;
}

.call-history.active p.duration span {
  color: #fff;
  margin-right: 6px;
}

.call-history.active p.duration {
  text-align: right;
  font-size: 12px;
  color: #ffffffe3 !important;
  font-weight: 500;
}

.tab-content .left-chat-box p,
.tab-content .right-chat-box p {
  color: #000;
}

.call-history-box {
  color: #fff !important;
}

.call-link {
  display: flex;
  justify-content: space-between;
}

.call-link span {
  opacity: 0.7;
}

.content-tab-bottom.tab-content p {
  font-size: 14px;
}

/* .tooltip.show {
  z-index: 999;
} */
.pdf-content-right-area .short_info {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 16px !important;
  color: #4d4d4d !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.report-insight-left-area.appointment_innerbox {
  padding-right: 229px;
}

.pdf-content-right-area.bottom-pdf-content.appointment_fullcontent {
  margin-right: 101px;
}

.pdf-content-right-area.appointment-content-text ul li {
  font-size: 14px;
}

.modal-footer .btn.btn-primary {
  background: #7269ef !important;
  border: none !important;
  color: #fff !important;
  cursor: pointer;
  font-size: 17px;
}

.history-btn-area {
  position: relative;
}

.history-btn-area span {
  position: absolute;
  top: 32px;
  right: -1px;
  font-size: 12px;
  z-index: 999;
}

.pdf-content-right-area ul {
  min-height: unset;
  list-style: unset;
  margin-left: 22px;
  font-size: 13px;
}

.loader-calling {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 32px 0 #fff, -32px 0 #fff;
  position: relative;
  animation: flash 0.5s ease-out infinite alternate;
  display: inline-block;
  left: 42px;
}

.upper-calling-loader {
  background: #000000a6;
  width: 100%;
  position: fixed;
  height: 100vh;
  z-index: 1000;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  z-index: 99999;
}

.upper-calling-loader p {
  font-size: 31px;
  font-weight: 600;
}

.previous,
.next {
  display: none !important;
}

.pagination li:last-child .page-link {
  border-bottom-right-radius: var(--bs-pagination-border-radius);
  border-top-right-radius: var(--bs-pagination-border-radius);
}

.pagination li a {
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  color: var(--bs-pagination-color);
  display: block;
  font-size: var(--bs-pagination-font-size);
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  position: relative;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.pagination li.active a {
  background: #7269ef !important;
  color: #fff !important;
}

@keyframes flash {
  0% {
    background-color: #FFF2;
    box-shadow: 32px 0 #FFF2, -32px 0 #FFF;
  }

  50% {
    background-color: #FFF;
    box-shadow: 32px 0 #FFF2, -32px 0 #FFF2;
  }

  100% {
    background-color: #FFF2;
    box-shadow: 32px 0 #FFF, -32px 0 #FFF2;
  }
}


.professional-tools {
  padding: 22px 0;
}

.professional-tools h4 {
  margin-bottom: 8px;
}

.professional-tools ul {
  min-height: unset;
}

.professional-tools ul li button {
  color: #000;
  line-height: 35px;
  border-radius: 12px;
  font-size: 15px;
  background: unset;
}

.privacy-box.professional-tools {
  text-align: left;
}

.privacy-box.professional-tools span {
  display: block;
  margin: 4px 0 0 0;
}

.partician-footer {
  padding: 9px 0;
  border-top: 1px solid #0000004a;
}

.privacy-box.professional-tools ul li span {
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
}

.react-calendar__tile.react-calendar__month-view__days__day.tile-class {
  background: #7269ef45;
}

.react-calendar__month-view__days__day {
  min-width: 13% !important;
}

.professional-tools h5 {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
  margin-bottom: 12px;
}

.professional-tools p {
  padding: 0 43px 0 0;
  font-size: 16px;
}

.privacy-box.professional-tools span:hover,
.professional-tools ul li button:hover,
.privacy-box a:hover {
  color: #655dd6 !important;
}

.professional-tools.opacit-0-3 ul li button,
.opacit-0-3 button {
  cursor: not-allowed;
  opacity: 0.4;
}

.professional-tools.opacit-0-3 ul li button:hover,
.professional-tools .opacit-0-3 button:hover {
  color: unset !important;
}

.upper-professional-section {
  border-top: 1px solid #00000036;
}

.left-chat-box.left-chat-typing-loader {
  position: absolute;
  background: #ffffffdb;
  height: 100%;
  z-index: 9999;
  width: 100%;
  left: 0;
  top: 0;
  border-radius: 12px;
}

.typing-loader-main-box {
  width: 6px;
  height: 6px;
  /* animation: line 1s linear infinite alternate; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 162px;
}

.typing-loader-main-box span {
  margin-right: 11px;
}

.calender-badge {
  margin-left: 4px;
}

.completed-text.badge {
  background: #7269ef;
  padding: 9px 17px;
  border-radius: 22px;
  margin-left: 6px;
}

.huge-benefits-content p {
  margin: 13px 0;
}

.small-spend-section {
  background: url("../../src/assests/images/bg-section.png") no-repeat;
  background-size: cover;
  width: 100%;
  padding: 72px 0;
}

.small-speed-content {
  color: #fff;
}

.small-speed-content h2 {
  font-size: 49px;
  font-weight: 800;
}

.small-headings {
  font-size: 42px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 2px;
}

.small-speed-images img {
  margin-left: 23px;
  text-align: right;
}

.small-speed-images {
  display: flex;
  justify-content: flex-end;
}

.huge-benefits-section {
  padding: 0 0 82px 0;
}

.expert-section {
  padding: 62px 0;
}

.expert-box {
  padding: 37px 0;
  border-radius: 21px;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  height: 100%;
  border: 1px solid;
}

.expert-box img {
  margin-bottom: 23px;
}

/* updated landing page css start */
.common-background-section {
  background: url("../assests/images/common-bg.jpg") no-repeat;
  background-size: cover;
  width: 100%;
  background-position: top;
}

.under-banner-section {
  padding: 252px 0;
}

.plan-text {
  background: linear-gradient(180deg, #4F43E4 0%, #963BAE 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-size: 81.333px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.plan-text-organize {
  color: #575757;
  text-align: center;
  font-size: 58px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.plan-text-maximum {
  background: linear-gradient(131deg, #5A12E5 55.7%, #986BEF 78.3%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 71px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  margin-top: 21px;
}

.get-started-btn.inner-get-started.btn.btn-primary {
  border-radius: 13.178px;
  background: var(--gredient, linear-gradient(180deg, #5A12E5 0%, #986BEF 100%)) !important;
  padding: 16px 62px;
  margin: 20px 0 0 0;
  text-align: center;
  font-size: 27px;
}

.under-image-section {
  text-align: center;
  margin: 72px 0 0 0;
}

.craft-box h5 {
  color: #0F0F0F;
  font-size: 21.293px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.craft-box h3 {
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  background: linear-gradient(180deg, #4F43E4 0%, #963BAE 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 12px 0;

}

.craft-box p {
  color: #424242;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin: 12px 0;
}

.padding-btm {
  padding-bottom: 100px;
}

.customer-section {
  background: url("../assests/images/customer-bg.png") no-repeat;
  background-size: cover;
  width: 100%;
  background-position: top;
  padding: 80px 0;
  color: #fff;
}

.customer-main-box h2 {
  color: #FFF;
  font-size: 52.604px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.726px;
}

.customer-main-box h6 {
  color: #FFF;
  font-size: 22.378px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.get-started-section {
  padding: 44px 0;
  text-align: center;
}

.enterprises-started-section {
  border-radius: 20px;
  background: var(--gredient, linear-gradient(180deg, #5A12E5 0%, #986BEF 100%));
  box-shadow: 0px 24px 24px 0px rgba(114, 107, 228, 0.40);
  color: #fff;
  padding: 102px 0;
}

.enterprises-started-section h2 {
  color: #FFF;
  text-align: center;
  font-size: 65.333px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 51px !important;
}

.enterprises-box {
  background: var(--gredient, linear-gradient(180deg, #5A12E5 0%, #986BEF 100%));
  stroke-width: 6.242px;
  filter: drop-shadow(0px 6.242px 6.242px rgba(0, 0, 0, 0.25));
  border: 3px solid;
  padding: 40px;
  border-radius: 13px;
  margin-bottom: 23px;
  height: 299px;
}

.enterprises-box img {
  width: 17%;
  margin-bottom: 23px;
}

.enterprises-box h5 {
  color: #FFF;
  font-size: 31.056px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 12px;
}

.work-with-us-box {
  border-radius: 20px;
  opacity: 0.6;
  background: var(--gredient, linear-gradient(180deg, #5A12E5 0%, #986BEF 100%));
  box-shadow: 0px 24px 24px 0px rgba(114, 107, 228, 0.40);
  padding: 52px;
  color: #fff;
}

.no-bg {
  background: transparent !important;
}

.work-with-us-box h2 {
  color: #FFF;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  text-transform: capitalize;
  margin-bottom: 21px;
}

.work-with-us-box p {
  color: #FFF;
  font-size: 20.286px;
  font-style: normal;
  font-weight: 500;
  line-height: 158.1%;
}

.work-with-us-box .get-started-btn.inner-get-started.btn.btn-primary {
  background: #fff !important;
  color: #5A12E5 !important;
  font-weight: 800;
  text-transform: uppercase;
  margin-top: 0 !important;
  font-size: 14px;
  padding: 14px 42px;
}

img.mobile-screen {
  position: absolute;
  left: 56%;
  top: 33%;
}

.work-with-us {
  padding: 12px 0 60px 0;
}

.bell-shrink {
  position: absolute;
  left: 123px;
  top: 113px;
}

.bell-shrink {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

/* updated landing page css end */

@media screen and (max-width: 1700px) {
  .width-wise-dashboard {
    padding: 0 220px 0 312px;
  }

  .inner-dashboard-section {
    padding: 0 30px 0 312px !important;
  }
}

@media screen and (max-width: 1600px) {
  .registration-area {
    background-repeat: no-repeat;
    height: 100vh;
    background-position: right;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .plan-text {
    font-size: 71.333px;
  }

  .plan-text-maximum {
    font-size: 53px;
  }

  .get-started-btn.inner-get-started.btn.btn-primary {
    padding: 16px 31px;
    font-size: 21px;
  }

  .plan-text-organize {
    font-size: 49px;
  }


  .card-shadow {
    padding: 19px;
  }

  .calender-list-view-section .appointment_innerbox {
    padding-right: 150px;
  }

  .width-wise-dashboard {
    padding: 0 120px 0 312px;
  }

  .content__container {
    height: 51px;
  }

  #container-main {
    right: 190px;
  }

  .hero-content h1 {
    font-size: 53px;
  }

  .hero-content span {
    font-size: 50px;
  }

  .hero-banner {
    margin: 0;
    height: unset;
  }

  .assests {
    left: 23%;
    width: 113px;
  }

  .user-details-section .container {
    max-width: 1340px;
  }
}




.rec {
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.mobile-show-look {
  display: none;
}

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 5px 0px rgb(51, 102, 255, 0.2);
  }

  65% {
    box-shadow: 0px 0px 5px 18px rgb(51, 102, 255, 0.2);
  }

  90% {
    box-shadow: 0px 0px 5px 73px rgb(51, 102, 255, 0);
  }
}

@media screen and (max-width: 1300px) {
  .login-right {
    min-width: 440px;
  }
  .essential-box h2.subheadings-main {
    font-size: 41px;
  }

  .bell-shrink {
    left: 104px;
    top: 93px;
  }

  .content__container {
    height: 48px;
  }

  .border-bottom-div h4 {
    font-size: 14px;
  }

  .border-bottom-div textarea.form-control {
    font-size: 14px;
  }

  .problem-box .add-btn {
    font-size: 11px;
  }

  #container-main {
    display: none;
  }

  .feature-inner p {
    line-height: 24px;
  }

  .sub-heading,
  .NFTICALLYAdvantageContent .text-light {
    font-size: 40px;
  }

  .google-login-btn button {
    width: 100%;
    padding: 10px 0px;
  }

  .google-login-btn {
    margin: 15px 10px 17px;
  }

  .awesome-inner-box {
    padding: 25px;
  }

  .feature-box {
    padding: 22px;
  }

  .current-plan {
    top: -51px;
  }

  .card-header {
    padding: 15px;
  }

  .card .btn.btn-link.text-dark.font-weight-bold.text-uppercase .collapsible-link.collapsed {
    text-align: left;
  }

}

@media screen and (max-width: 1199px) {
  .hero-content h1 {
    font-size: 37px;
  }

  .bell-shrink {
    left: 84px;
    top: 74px;
  }

  .enterprises-started-section h2 {
    font-size: 45.333px;
  }

  .enterprises-box h5 {
    font-size: 24.056px;
  }

  .enterprises-box {
    height: 259px;
  }

  .work-with-us-box h2 {
    font-size: 26px;
  }

  .craft-box h3 {
    font-size: 38px;
  }

  .craft-box p {
    font-size: 15px;
  }

  .craft-box h5 {
    font-size: 17.293px;
  }

  .small-speed-content h2,
  .small-headings {
    font-size: 37px;
  }

  .video-history-popup-box.modal .nav-item {
    height: 100%;
  }

  .call-link {
    flex-direction: column;
  }

  .inner-dashboard-section {
    padding: 0 30px 0 52px !important;
  }

  .inner-dashboard-section .card-shadow {
    margin-bottom: 12px;
  }

  .chat-animated-button.doctor-side-pannel {
    top: 162px;
    left: 5px;
  }

  .doctor-left-pannel {
    display: none;
  }

  .doctor-left-pannel.mobile-view-bookmark {
    display: block !important;
    z-index: 999;
  }

  .width-wise-dashboard {
    padding: 0 30px 0 30px;
  }

  .header .container {
    max-width: 1160px;
  }

  .content .btn.btn-primary {
    padding: 11px 27px;
  }

  .atroy-inner-box b {
    font-size: 16px;
  }

  .atroy-inner-box p {
    font-size: 14px;
  }

  .content__container__text {
    font-size: 38px;
  }

  .hero-banner video {
    height: 502px;
  }

  .card button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link.collapsed,
  button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link {
    font-size: 16px;
  }

  .card button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link.collapsed:after,
  .card button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link:after {
    font-size: 10px;
    right: 19px;
  }

  .capabilities-section p {
    font-size: 14px;
  }

  .capabilities-section .card-body {
    padding: 12px 30px !important;
  }

  .health-right-box h5 p {
    font-size: 15px;
    line-height: 23px;
  }

  .health-left-area h3 {
    font-size: 20px;
    padding: 11px 26px;
  }

  .health-left-area h2 {
    font-size: 22px;
  }

  .user-details-section .container {
    max-width: 1140px;
  }

  .user-side .icons-left {
    margin-top: 21px;
  }

  .appointment_fullcontent span {
    font-size: 14px;
  }

  .user-text img {
    width: 21px;
    margin-left: 3px;
  }

  .user-text {
    font-size: 16px;
  }

  .profile-card p,
  .heathy-box p {
    font-size: 14px;
  }

  .sub-heading,
  .NFTICALLYAdvantageContent .text-light {
    font-size: 30px;
  }

  .feature-box {
    height: 462px;
    padding: 12px !important;
  }

  .atorny-card {
    height: 470px;
  }

  .atroy-inner-box {
    padding: 17px;
  }

  .chat-animated-button {
    display: block;
    position: absolute;
    left: 10px;
    top: 80px;
    z-index: 999;
  }

  .mid-chat-box {
    border-radius: 21px;
    border: 1px solid #7269ef;
    max-height: unset;
    position: absolute;
    height: unset;
    width: 96%;
    left: 11px;
    background: #fff;
    right: 11px;
    top: 132px;
  }

  .left-chat-boxes.y.mobile-view-bookmark {
    position: absolute;
    left: 0;
    top: 61px;
    background: #fff;
    z-index: 99;
    display: block;
    height: -moz-fit-content;
    min-height: 100% !important;
    max-height: inherit;
    transition: 0.2s ease-in-out;
    border-radius: 0;
    border: none;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
  }

  .mobile-view-bookmark .chat-history-area {
    max-height: unset;
  }

  .position-unset {
    position: unset;
  }

  .left-chat-boxes {
    display: none;
  }

  .appointment-content-text h3 {
    font-size: 16px;
  }

  .appointments_box.request_appointments_box {
    padding-left: 13px;
    padding-right: 13px;
  }

  .appointments_box {
    padding: 13px 290px 13px 125px;
  }

  .doctor-side .icons-left {
    margin-top: 18px;
  }

  .appointments_box.request_appointments_box p {
    font-size: 14px;
  }

  .complete-text.btn.btn-unset {
    padding: 5px 11px;
  }

  .completed-text.badge.bg-success {
    font-size: 13px;
  }

  .completed-text.badge.bg-warning {
    font-size: 13px;
  }

  .short_info.purple-txt {
    margin-top: 4px;
  }

  .doctor-side .appointment_fullcontent {
    margin-top: -30px !important;
  }

  .feature-box,
  .feature-box.main-feature {
    padding: 30px 0;
  }

  .hero-content span {
    font-size: 37px;
  }

  .hero-banner {
    padding-bottom: 50px;
  }

  .feature-inner span {
    padding: 0;
  }

  .feature-box,
  .feature-box.main-feature {
    padding: 30px;
  }

  .card1 {
    width: 90%;
  }

  .payment-add-card h4 {
    font-size: 17px;
  }

  .chatbox-body {
    padding: 8px 22px;
    height: 462px;
  }

  .left-chat-box p {
    padding: 9px 10px;
    margin: 0 0 6px 0;
  }

  .chatbox-header {
    color: #000;
  }

  .left-chat-boxes {
    overflow: auto !important;
    min-height: 100% !important;
  }

  .left-chat-boxes,
  .right-chat-boxes {
    padding: 72px 11px 10px;
  }

  .awesome-inner-box {
    padding: 30px;
    height: 100%;
  }
}

@media screen and (max-width: 991px) {
  .profile-content-area.my-profile-content {
    padding: 20px;
  }

  .plan-text-organize {
    font-size: 40px;
  }

  .under-banner-section {
    padding: 152px 0;
  }

  img.mobile-screen {
    width: 34%;
  }

  .craft-box {
    margin-top: 52px;
    text-align: center;
  }

  .bell-shrink {
    left: 136px;
    top: 123px;
  }

  .enterprises-box {
    height: 309px;
  }

  .customer-main-box h6 {
    font-size: 16.378px;
  }

  .customer-main-box h2 {
    font-size: 40.604px;
  }

  .work-with-us .d-flex.align-items-center.justify-content-between {
    flex-wrap: wrap;
  }

  .work-with-us-box p {
    margin-top: 19px;
  }

  .pdf-content-right-area.bottom-pdf-content.appointment_fullcontent {
    margin-right: 101px !important;
  }

  .inner-dashboard-section .card-shadow img {
    width: 15%;
  }

  .report-insight-section .appointment_rightarea {
    width: fit-content;
    z-index: 998;
  }

  .appointment-boxes.report-insight-area {
    padding-top: 70px;
  }

  .chat-animated-button.doctor-side-pannel {
    top: 120px;
  }

  .doctor-left-pannel {
    top: 141px;
  }

  .appointment-list-view {
    margin-top: 42px;
  }

  .list-view-box .list-inner {
    width: 34px;
    height: 32px;
  }

  .list-view-box img {
    width: 44%;
  }

  .left-gnerate .no-data-box {
    position: unset;
    transform: unset;
  }

  .generate-history-box {
    height: unset;
    margin-bottom: 12px;
  }

  .transcription-box {
    margin-top: 12px;
  }

  .header .container {
    max-width: 98%;
  }

  .mobile-tab .activate-pro-faq {
    display: unset;
  }

  .mobile-tab .header-right {
    display: block;
  }

  .dropdown-menu.show {
    right: 0px !important;
    padding: 15px;
    width: 182px !important;
  }

  .dropdown-top.mobile-tab .dropdown {
    align-items: center;
    justify-content: center;
  }

  .dropdown-top .dropdown-toggle::after {
    display: none !important;
  }

  .dropdown-top .dropdown button {
    color: #7269ef !important;
    font-size: 30px;
    /* margin-top: -20px; */
  }

  .dropdown-top.mobile-tab {
    display: block;
  }

  .header-right {
    display: none;
  }

  .header-right.header-right-doctor {
    display: block !important;
  }

  .header-top-right {
    margin-right: 28px;
  }

  .atroy-inner-box {
    height: 81%;
  }

  .atorny-card:before {
    width: 111%;
  }

  #container-main {
    display: none;
  }

  .header-top-right p,
  .header-top-left p {
    font-size: 14px;
  }

  .content__container__list__item {
    line-height: 42px;
    font-size: 27px;
  }

  .google-logo-style {
    width: 15px;
    height: 15px;
  }

  .availabilty-box h5 {
    font-size: 14px !important;
  }

  .availability-box .container {
    max-width: 950px;
  }

  .profile-card-inner {
    margin: 12px 0;
  }

  .feature-box {
    height: 366px;
    padding: 22px !important;
  }

  .availabilty-box .d-flex {
    justify-content: center;
  }

  .timing-zone {
    margin-bottom: 7px;
    /* width: 28%; */
  }

  .css-4jnixx-MuiStack-root>.MuiTextField-root {
    min-width: unset !important;
  }

  .delete-text {
    right: 0;
  }

  .atorny-card {
    height: 310px;
  }

  .content__container__text {
    font-size: 27px;
    line-height: 45px;
    margin-bottom: 0 !important
  }

  .hero-banner video {
    height: 442px;
  }

  .dosage-area select#formBasicEmail {
    width: 25% !important;
  }

  .appointment_fullcontent span {
    font-size: 14px;
  }

  .appointments_box.open .appointment_fullcontent {
    line-height: 22px;
  }

  .appointments_box.open .appointment_fullcontent {
    margin-right: 210px;
  }

  .appointment-boxes {
    padding: 8px 0;
  }

  .appointment-area ul li {
    min-width: 90px;
  }

  .appointment-area ul {
    height: 40px;
  }

  .appointment-area ul li.active {
    min-width: 111px;
  }

  .accordion-button::after {
    right: 18px;
    bottom: 50px;
  }

  #counter {
    bottom: -29px;
    right: 45%;
  }

  .hero-banner .row {
    flex-direction: column-reverse;
  }

  .hero-content span {
    font-size: 40px;
  }

  .hero-content h1,
  .hero-content span {
    font-size: 27px;
    line-height: 41px;
    padding: 0;
  }

  .hero-img {
    text-align: center;
    margin-top: 40px;
  }

  .hero-content {
    margin-bottom: 20px;
  }

  .hero-img img {
    width: 500px;
    height: auto;
  }

  .features-section .row {
    justify-content: center;
  }

  .NFTICALLYAdvantageContent p {
    margin-bottom: 30px;
  }

  button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link.collapsed,
  button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link {
    text-align: left;
  }

  .dropdown-top.mobile-tab .dropdown-menu .active:not(.form-control) {
    background-color: transparent !important;
  }

  .dropdown-top.mobile-tab .email-dropdown {
    margin-left: 8px;
    flex-direction: row-reverse;
  }

  .email-dropdown.mobiel-inner-style {
    display: block;
  }

  .mobile-tab .dropdown-top button {
    background: transparent !important;
    font-size: 15px;
    font-weight: 600;
  }

  .header-top-right:before {
    display: none;
  }

  .inner-content-main a {
    color: #000;
    display: block;
    margin: 0 0 9px;
    font-size: 14px;
  }

  .inner-content-main {
    margin-top: 11px;
    margin-left: 0;
  }

  .mobile-tab .header-top-right {
    margin: 0 0 12px 0;
  }
}

@media screen and (max-width: 768px) {
  .capabilities-section h2:after {
    right: 15px;
  }

  .plan-text {
    font-size: 41.333px;
  }

  .plan-text-organize {
    font-size: 30px;
  }

  .pdf-content-right-area.appointment-content-text h3 {
    overflow: hidden !important;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
  }

  .plan-text-maximum {
    font-size: 39px;
  }

  .get-started-btn.inner-get-started.btn.btn-primary {
    padding: 7px 31px;
    font-size: 17px;
  }

  img.mobile-screen {
    width: 31%;
    top: 43%;
    left: 58%;
  }

  .bell-shrink {
    left: 99px;
    top: 85px;
  }

  .customer-main-box {
    margin: 0 0 22px 0;
  }

  .customer-section {
    padding: 50px 0;
  }

  .enterprises-started-section h2 {
    font-size: 28.333px;
  }

  .enterprises-box {
    height: unset;
  }

  .privacy-content {
    font-size: 15px;
  }

  img.anime-3 {
    top: -20px;
  }

  .health-right-box h5 p,
  .modal-area-box .right-chat-box-dropdown label.form-label,
  .modal-area-box .right-chat-box-dropdown .form-control {
    font-size: 14px;
  }

  .completed-text.badge.bg-success {
    font-size: 13px;
    font-weight: 49;
  }

  #container-main {
    right: 210px;
  }

  .login-img {
    display: none;
  }

  .pricing-area {
    min-height: calc(100vh - 112px);
  }

  .appointment-content-area-right {
    text-align: start;
    margin-top: 9px;
  }

  #counter {
    right: 40%;
  }

  .appointment-area {
    flex-wrap: wrap;
  }

  .appointment-area ul {
    margin-top: 12px;
  }

  .appointment-content-area-right-bottom {
    justify-content: start;
  }

 

  .call-to-action-right {
    justify-content: center;
    margin-top: 30px;
    text-align: center;
  }

  .cancel-area-box button {
    width: fit-content !important;
  }

  .privacy-box {
    text-align: center;
  }

  p.attorney-copyright {
    text-align: center;
    padding-bottom: 7px;
  }

  .payment-add-card h4 {
    font-size: 20px;
  }

  .payment-detail-area {
    height: unset;
  }

  .saved-cards-area {
    height: unset;
  }

  .assests {
    display: none;
  }

  .login-right {
    min-width: unset;
  }

  .login-right h6 {
    font-weight: 600;
  }

  .google-login-btn .btn-unset {
    margin: 0 !important;
  }

  .google-login-btn button {
    padding: 10px 40px;
  }

  .content__container {
    height: 44px;
  }
}

@media screen and (max-width: 767px) {
  .dropdown-spacing .dropdown-menu.show {
    padding: 4px 0;
  }
  .essential-box h2.subheadings-main {
    font-size: 31px;
    line-height: normal;
    letter-spacing: 0;
}

  .privacy-box.professional-tools ul li span {
    margin: 0;
  }

  .professional-tools {
    padding: 14px;
  }

  .upper-professional-section {
    padding: 11px 0;
  }

  .professional-tools ul li a {
    font-size: 17px;
  }

  .flex-column {
    flex-direction: row !important;
    display: flex;
    flex-wrap: nowrap;
  }

  .call-history {
    min-width: fit-content;
    margin-right: 15px;
  }

  .call-history-tab-left {
    height: unset;
    overflow: auto;
    margin-bottom: 4px;
    z-index: 999;
    position: relative;
  }

  .report-insight-section .appointments_box.open .appointment_fullcontent {
    margin-bottom: 45px;
  }

  .calender-boxes {
    padding-top: 15px;
  }

  .record-menu.mobile-menu-recorded,
  .view-menu.mobile-menu-recorded {
    display: block;
  }

  .record-menu.desktop-menu-recorded,
  .view-menu.desktop-menu-recorded {
    display: none;
  }

  .recorded-upper-box {
    display: flex;
  }

  .recorded-upper-box .recorde-outer {
    margin-left: 12px;
    margin-top: 6px;
  }

  /* .animated-btn {
    padding: 9px 11px;
} */
  .chat-animated-button {
    right: 16px;
  }

  .google-signp {
    margin-top: 11px;
  }

  .content__container {
    height: 42px;
  }

  .pricing-area.under-appointment .container {
    max-width: 770px !important;
  }

  .appointment-content-text {
    width: 71%;
  }

  .action-text img {
    width: 22px;
    margin-right: 4px !important;
  }

  .save-btn button {
    font-size: 14px;
  }

  .dropdown-top.menu-profile.logout-btn .dropdown-toggle::after {
    right: 0;
    display: block;
  }

  .header-right-doctor .dropdown-item {
    font-size: 14px;
  }

  .header-right.header-right-doctor {
    display: block;
  }

  .profile-card {
    margin-bottom: 12px;
  }




  .dropdown-top.mobile-tab .dropdown button:active:focus {
    background-color: #fff !important;
  }

  .content .btn.btn-primary {
    margin: 11px auto;
    padding: 11px 27px;
  }

  #counter {
    right: 41%;
  }

  .atroy-inner-box {
    height: 61%;
  }

  .atorny-card:before {
    width: 41%;
    height: 250%;
  }

  .atroy-inner-box {
    height: 91%;
  }

  .call-to-action-left {
    text-align: center;
  }

  .hero-content p br {
    display: none;
  }

  .common-padding {
    padding: 40px 0;
  }

  .content {
    padding: 0 42px;
  }

  .features-section.common-padding .atorny-card {
    height: 230px;
  }

  .main-hero-section {
    padding: 79px 0 0 0;
  }

  .doctor-details-box {
    margin-bottom: 17px;
  }

  .problem-box .add-btn {
    margin-top: 11px;
  }

  .appointment_innerbox {
    padding-right: 150px;
  }

  .dropdown-top.logout-btn-mobile button {
    background: transparent !important;
  }


  #container-main {
    right: 250px;
  }

  .atorny-card {
    height: 220px;
    margin-bottom: 8px;
  }

  .health-right-box {
    border-left: none;
  }

  .content__container__list {
    text-align: center !important;
  }

  .content__container__text {
    text-align: center;
  }

  .feature-box {
    height: 306px;
    margin-bottom: 12px;
  }

  .feature-box img {
    left: 43%;
  }

  .main-hero-img {
    margin-top: 42px;
  }

  /* .available-boxes-main {
    width: 73%;
  } */

  .delete-text {
    top: 128px;
  }

  .appointments_box.open .appointment_fullcontent {
    margin-right: 0;
  }



  .dropdown-top .dropdown-toggle::after {
    display: none;
  }




  .header-top-right:before {
    display: none;
  }

  .google-logo-style {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }

  .mob-space-top {
    margin-top: 40px;
  }
}

@media screen and (max-width: 575px) {
  .hero-banner video {
    height: 450px;
  }

  .plan-text-organize {
    font-size: 23px;
  }

  .plan-text-maximum {
    font-size: 28px;
    margin: 8px 0 0 0;
  }

  .plan-text {
    font-size: 34.333px;
    margin-bottom: 9px;
  }

  img.mobile-screen {
    top: 36%;
  }

  .craft-box h3 {
    font-size: 28px;
  }

  .customer-main-box h2 {
    font-size: 30.604px;
  }

  .enterprises-box {
    padding: 18px;
  }

  .enterprises-box img {
    width: 8%;
  }

  .work-with-us-box p {
    font-size: 18.286px;
  }

  .under-banner-section {
    padding: 102px 0;
  }

  .craft-section .align-items-center.padding-btm.row {
    background: #7539e914;
    margin: 0 0 11px 0;
    padding: 52px 23px;
  }

  .bell-shrink {
    left: 105px;
    top: 95px;
  }

  .web-look {
    display: none;
  }

  .mobile-show-look {
    display: block;
  }

  .huge-benefits-content {
    text-align: center;
    margin-bottom: 42px;
  }

  .small-speed-images {
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 31px;
  }

  .small-speed-content {
    text-align: center;
  }

  .expert-box {
    height: unset;
    margin-bottom: 13px;
  }

  .small-speed-images img {
    margin-bottom: 22px;
    margin-left: 0;
  }

  .professional-tools p {
    padding: 0;
  }

  .appointment_togglebtn {
    width: 23px;
    height: 23px;
  }

  .professional-tools {
    margin-top: 0;
  }

  .pdf-left-area img {
    width: 85px !important;
    height: 85px;
    min-width: 85px !important;
    min-height: 85px;
  }

  .professional-tools i {
    font-size: 18px;
  }

  .professional-tools ul li a,
  .privacy-box.professional-tools ul li span {
    font-size: 15px;
  }

  .report-area-bootom-btn button {
    width: 26px;
    height: 26px;
    min-height: 24px;
    padding: unset;
  }

  .report-insight-section .doctor-side .icons-left {
    margin-top: 3px !important;
  }

  .doctor-side .icons-left {
    margin-top: 13px !important;
  }

  .call-history-box {
    width: 25px;
    min-width: 25px;
    height: 25px;
    min-height: 25px;
  }

  .report-insight-left-area.appointment_innerbox {
    padding-right: 176px;
  }

  .video-history-popup-box .modal-content {
    padding: 5px;
    width: 93%;
    margin: 0 auto;
  }

  .video-history-popup-box .modal-body {
    padding: 5px;
  }

  .inner-dashboard-section .card-shadow {
    justify-content: start;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 12px;
    padding-left: 51px;
  }

  .call-history {
    margin: 0px 5px 0 0;
  }

  .common-spacing-area.pricing-area.report-insight-section {
    padding-top: 60px;
  }

  .appointment-boxes.report-insight-area {
    padding-top: 89px;
  }

  .report-insight-section .appointments_box.open .appointment_fullcontent {
    margin-left: 85px;
    margin-top: -45px;
    padding-right: 0;
    margin-right: 61px !important;
  }

  .pdf-content-right-area.bottom-pdf-content.appointment_fullcontent {
    margin-top: -66px !important;
  }

  .calender-list-view-section .appointments_box.open .appointment_fullcontent {
    margin-right: 110px;
    margin-left: 74px;
  }

  .calender-list-view-section .appointment-content-text {
    width: 100%;
  }

  .appointment-list-view h4 {
    text-align: center;
  }

  .calender-boxes {
    padding-top: 24px;
  }

  .icons-left.doctro-side-dashboard {
    margin-top: 11px !important;
  }

  .chat-animated-button.doctor-side-pannel {
    top: 160px;
  }

  .doctor-left-pannel {
    top: 181px;
  }

  .width-wise-dashboard {
    padding: 0 20px 0 20px;
  }

  .promt-section-iiner {
    padding: 25px 0;
  }

  .promt-main-box h4 {
    margin-left: 12px;
    font-size: 14px;
  }

  .prompt-box-modal .modal-dialog {
    max-width: 464px;
    margin: 0 auto !important;
  }

  .call-to-action {
    padding: 29px 20px;
  }

  .mid-chat-box {
    top: 125px;
  }

  .upload-family-img {
    padding: 22px 0;
  }

  .inner-chatbot-main {
    flex-direction: column;
    align-items: baseline;
  }

  .single-family-popup img {
    width: 70%;
  }

  .inner-chatbot-main .active-tabs {
    margin-top: 11px;
  }

  .insurance-type-field input {
    font-size: 14px;
  }

  .content__container {
    height: 45px;
  }

  .border-bottom-div.last-border .d-flex {
    flex-wrap: wrap;
  }

  div#swal2-html-container {
    font-size: 15px;
  }

  #swal2-title {
    font-size: 17px;
  }

  .key-point-box.history-box .d-flex {
    align-items: center;
  }

  .appointment_rightarea {
    z-index: 998;
  }

  .button-section .add-btn {
    height: 26px;
    width: 26px;
  }

  .modal-title.h4 {
    font-size: 23px;
  }

  .profile-card-inner {
    margin: 8px 0;
  }

  .clock-main {
    flex-wrap: wrap;
  }

  .prompt {
    top: 14px;
  }

  .right-chat-box p {
    font-size: 14px;
  }

  .inner-chatbot {
    padding: 79px 0 0px;
  }

  .main-hero-section {
    padding: 91px 0 0 0;
  }

  .main-hero-img {
    margin-top: 22px;
  }

  .timing-zone {
    width: 47%;
  }

  .timing-zone .css-4jnixx-MuiStack-root {
    overflow: unset !important;
  }

  .availabilty-box .d-flex {
    justify-content: left;
    margin-top: 8px;
  }

  .available-boxes-main {
    margin-top: 12px;
  }

  .card button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link.collapsed,
  button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link {
    font-size: 16px;
  }

  .save-btn button {
    font-size: 14px;
  }

  .download_box {
    background: #7269EF;
    color: #fff;
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 5px;
    /* filter: brightness(0) invert(1); */
    /* margin-left: 9px; */
  }

  .download_box .fa {
    font-size: 11px;
  }

  .update-btn {
    padding: 5px 20px;
    font-size: 14px;
  }

  .profile-card-inner .col-sm-4.mt-3.col-6 {
    margin-top: 0 !important;
  }

  .profile-card-inner .col-sm-4.col-6 {
    margin-bottom: 18px;
  }

  .dosage-area select#formBasicEmail {
    margin-left: 7px;
  }

  .chat-animated-button {
    right: 10px;
  }

  /* .chat-animated-button .animated-btn {
    background: #fff !important;
    padding: 2px 16px;
    border: 1px solid #7269ef;
    color: #7269ef;
    border-radius: 42px;
  } */

  .content__container__list__item {
    font-size: 34px;
  }

  .content__container__text {
    font-size: 36px;
  }

  p.attorney-copyright,
  .privacy-content {
    font-size: 13px;
  }

  .doctor-side .icons-left {
    margin-top: 81px;
  }

  .user-side .icons-left {
    margin-top: 81px;
  }

  .login-box {
    padding: 34px 29px;
  }

  .add-notes {
    margin-bottom: 16px;
  }

  .appointments_box.open .appointment_fullcontent {
    margin-left: 65px;
    margin-top: -45px;
    padding-right: 0;
  }

  .appointments_box.request_appointments_box p {
    font-size: 13px;
  }

  .appointments_box {
    margin-top: 11px;
    margin-left: 12px;
    margin-right: 12px;
  }

  .appointment-area ul li {
    font-size: 15px;
    min-width: 110px;
  }

  .appointment_innerbox {
    padding-right: 170px;
  }

  .appointment_fullcontent span {
    font-size: 13px;
  }

  .appointment-content-image img {
    min-width: 59px;
    height: 59px;
    width: 59px;
  }

  .appointments_box {
    padding: 10px 287px 10px 125px;
  }

  i.fa.fa-phone {
    width: 23px;
    min-width: 23px;
    font-size: 12px;
    height: 23px;
    min-height: 23px;
  }

  .completed-text.badge.bg-success {
    padding: 7px 8px;
    font-size: 11px;
  }

  .appointment-content-text h3 {
    font-size: 15px;
    margin-bottom: 2px !important;
  }

  .appointment-boxes {
    padding: 25px 29px;
  }

  .user-under-box h1 {
    font-size: 21px;
  }

  .cancel-btn.btn.btn-unset {
    border: none !important;
  }

  .privacy-content {
    margin: 90px 6px;
  }

  .modal-dialog {
    width: 100%;
  }

  .file-uploaded {
    border-radius: 122px;
    border: 2px solid #7269efcc;
  }

  /* .dropdown-top.mobile-tab {
    display: block;
  } */
  .terms-label label {
    float: unset;
  }

  .login-form-box {
    padding: 19px;
  }

  /* .cancel-btn.btn.btn-unset {
    width: 100% !important;
  } */
  .upgrade-to-pro-style {
    width: 100% !important;
  }


  .feature-inner span {
    left: 0;
  }

  .mobile-tab .dropdown-top .dropdown-toggle::after {
    display: none !important;
  }

  .dropdown-top .dropdown-toggle::after {
    display: none;
  }

  .mobile-tab .dropdown-menu.show {
    max-width: 522px;
    right: 0 !important;
    padding: 15px;
  }

  .mobile-tab .email-dropdown {
    display: block;
    margin-left: 0;
  }

  .mobile-tab .activate-pro-faq {
    display: unset;
  }

  .mobile-tab .header-top-right:before {
    display: none;
  }

  /* .header-right {
    display: none;
  } */
  .hero-banner {
    border-radius: 0;
  }

  .current-plan {
    top: -20px;
    right: 10px;
  }

  .hero-content {
    padding: 0;
  }

  .hero-content h1 {
    font-size: 27px;
  }

  .hero-content span {
    font-size: 33px;
  }

  .NFTICALLYAdvantageContent .text-light {
    font-size: 30px;
  }

  .health-left-area {
    padding: 30px 0;
  }

  .health-left-area h3 {
    font-size: 19px;
    padding: 11px 27px;
  }

  .health-right-box h5 p {
    font-size: 15px;
    line-height: 24px;
  }

  .login-area .row {
    flex-direction: column-reverse;
  }

  .modal-dialog {
    width: 100%;
    margin: unset !important;
  }

  .modal-title h5 {
    color: #000;
    font-weight: 600;
  }

  .login-left {
    display: none;
  }

  .on-boarding-login {
    height: 100vh;
    background: linear-gradient(239deg,
        rgba(255, 240, 160, 1) 0%,
        rgba(113, 104, 236, 1) 49%,
        rgba(59, 46, 228, 1) 100%);
  }

  .login-right {
    min-width: 80%;
    background: #fff;
  }

  .chatbox-header h3 {
    font-size: 20px;
    font-weight: 600;
  }

  .left-chat-box p {
    padding: 9px 15px;
    font-size: 14px;
    background: #e5e5e5;
  }

  .prompt:hover,
  .btn:focus-visible {
    font-size: 14px;
    font-weight: 600;
  }

  /* .modal-footer {
    display: none;
  } */

  .prompt-box .dropdown-item p {
    background: #ededed;
    padding: 7px 8px;
    font-size: 12px;
    color: #000;
  }

  .prompt {
    font-size: 13px;
  }

  .mid-chat-box input,
  .mid-chat-box textarea {
    font-size: 13px;
  }

  .pricing-top-heading-area h4 {
    font-size: 35px;
  }

  .profile-content-area input,
  .profile-content-area .form-select {
    font-size: 14px;
  }

  .profile-content-area.my-profile-content {
    padding: 18px 20px;
  }
}

@media screen and (max-width: 480px) {
  .cancel-plan-box-area .modal-content {
    width: 100%;
  }

  .report-insight-left-area.appointment_innerbox {
    padding-right: 55px;
  }

  .appointments_box.request_appointments_box p {
    font-size: 11px;
  }

  .report-insight-section .appointments_box.open .appointment_fullcontent {
    padding-right: 0;
    margin-right: 21px !important;
  }

  .inner-dashboard-section .card-shadow {
    width: 92%;
  }

  .card-contents.inner-card-content-main.w-100.align-items-cente.justify-content-between {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .time-period {
    max-width: 100%;
    margin-bottom: 9px;
  }

  .card-shadows {
    padding: 13px;
  }

  .report-insight-area.appointment-boxes .appointment-area h2 {
    margin-bottom: 14px;
  }

  .report-insight-section .doctor-side .icons-left {
    margin-top: 58px;
  }

  .report-insight-section .appointment-content-text {
    width: 100%;
  }

  .calender-list-view-section .appointments_box.open .appointment_fullcontent {
    margin-top: -23px !important;
    margin-right: 30px;
  }

  .chat-animated-button.doctor-side-pannel {
    top: 205px;
  }

  .doctor-left-pannel {
    top: 221px;
  }

  .icons-left.doctro-side-dashboard {
    margin-top: 81px !important;
  }

  .list-view-box {
    margin-top: 18px;
  }

  .outer-dashboard-section {
    padding: 3px 0;
  }

  .timing-zone .css-160unip>.MuiTextField-root {
    min-width: 160px !important;
  }

  .timing-zone .css-160unip {
    overflow: unset !important;
  }

  .prompt-box-modal .modal-dialog {
    max-width: 404px;
  }

  .inner-health .form-control {
    font-size: 14px !important;
  }

  .slot-changes p {
    font-size: 14px;
  }

  .slot-changes {
    flex-wrap: wrap;
  }

  .slot-changes a {
    margin-left: 0;
  }

  .add-btn {
    padding: 4px 5px;
  }

  .appointment_innerbox {
    flex-wrap: wrap;
  }

  .appointment-content-area-image .no-data {
    width: 120px !important;
    height: 120px !important;
  }

  .outer-no-data p {
    font-size: 17px;
  }

  .appointment-content-text {
    margin-top: 11px;
  }

  .appointments_box.open .appointment_fullcontent {
    padding-right: 0;
    margin-top: 0 !important;
    margin-left: 0 !important;
  }

  .appointment-area {
    flex-direction: column;
  }

  .data-text p {
    font-size: 14px;
  }

  .heathy-box h6 img {
    width: 15px;
    height: 15px;
  }

  .profile-card-inner .col-sm-4.col-6 {
    margin-bottom: 8px;
  }

  .profile-img {
    width: 92px !important;
    height: 92px !important;
  }

  .overflow h5 {
    font-size: 14px;
  }

  .add-chat-btn button,
  .chat-bookmark-area p {
    font-size: 13px;
  }

  .chat-history-left {
    font-size: 13px;
  }

  #counter {
    right: 31%;
  }

  #swal2-html-container {
    font-size: 13px;
  }

  .chatbox-header h3 {
    font-size: 16px;
  }

  .swal2-styled.swal2-confirm {
    padding: 9px 25px !important;
    font-size: 13px !important;
  }

  .chatbox-body {
    padding: 8px 10px;
  }

  .left-chat-box {
    margin-top: 0;
  }

  .submit {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }

  .submit .fa-paper-plane {
    font-size: 16px;
  }

  .mobile-tab .header-top-right {
    margin: 0 0 4px 0;
  }

  .inner-content-main a {
    margin: 0 0 3px;
  }

  /* .inner-content-main {
  margin-top: 0;
} */
  .chat-animated-button {
    top: 74px;
    z-index: 999;
    right: 0;
  }

  .features-section.common-padding .atorny-card {
    height: 270px;
  }

  .medication-table .table>:not(:last-child)>:last-child>* {
    min-width: 142px;
  }

  .content__container__list__item,
  .call-to-action-left h4 {
    font-size: 25px;
  }

  .medication-modal .modal-content,
  .modal-card {
    overflow: unset !important;
  }

  .action-text #button-tooltip {
    z-index: 9;
  }

  div:where(.swal2-container).swal2-center>.swal2-popup {
    width: 79%;
  }

  .modal-title.h4 {
    font-size: 23px;
  }

  .border-bottom-div h4 {
    font-size: 14px;
  }

  .hero-content span {
    font-size: 27px;
  }

  .call-to-action {
    padding: 12px 0;
  }

  .card button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link.collapsed:after,
  .card button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link:after {
    font-size: 9px;
    right: 10px;
    top: 26px;
  }

  .NFTICALLYAdvantageContent .text-light,
  .sub-heading {
    font-size: 24px;
  }

  .content__container__text {
    font-size: 25px;
    line-height: 32px;
  }

  .add-notes .modal-dialog {
    min-width: unset !important;
  }

  footer {
    margin-top: 20px !important;
  }

  #container-main {
    display: none;
  }

  .availabilty-box h5 {
    font-size: 12px;
  }

  .modal-dialog {
    width: 100%;
    margin: unset !important;
  }

  .NFTICALLYAdvantageContent .text-light {
    font-size: 27px;
  }

  .hero-banner video {
    height: 360px;
  }

  .captcha-size {
    transform: scale(0.70);
    display: flex;
    justify-content: center;
  }

  .privacy-box {

    justify-content: center;
    flex-wrap: wrap;
  }

  .terms-condition-box-area h5 {
    font-size: 20px;
  }

  .terms-condition-box-area label.form-check-label {
    text-align: left;
  }

  .terms-condition-modal-area-area .modal-dialog {
    width: 90%;
    margin: 0 auto !important;
  }

  .delete-text {
    right: -7px;
  }
}

@media screen and (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
  .inputs input {
    padding: 9px !important;
    margin: 2px !important;
}

  .small-speed-content h2,
  .small-headings {
    font-size: 27px;
  }

  .inner-dashboard-section .card-shadow {
    padding-left: 29px;
  }

  .card-content p {
    font-size: 14px;
  }

  .report-insight-section .appointment-content-text {
    margin-bottom: 40px;
  }

  .report-insight-section .doctor-side .icons-left {
    margin-top: 98px;
  }

  .report-insight-section .appointments_box.open .appointment_fullcontent {
    margin-top: -37px !important;
  }

  .recorded-upper-box {
    flex-direction: column;
    display: unset;
  }

  .recorded-upper-box .recorde-outer {
    margin-left: 0;
  }

  .promt-section-iiner {
    padding: 18px 0;
  }

  .icons-left .fade.toast {
    top: 1px;
  }

  .left-chat-box p {
    padding: 3px 15px;
  }

  .reason-boxes .form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer;
    font-size: 12px;
  }

  .main-hero-section {
    padding: 80px 0 40px 0;
  }

  .hero-content {
    padding: 12px;
  }

  .call-to-action-left p {
    font-size: 15px;
  }

  .health-right-box h5 {
    margin-bottom: 15px;
  }

  .modal-title.h4 {
    font-size: 17px !important;
  }

  .appointment_innerbox {
    padding-right: 80px;
  }

  .appointment-modal .form-label {
    font-size: 13px;
  }

  .clock-main {
    flex-wrap: wrap;
  }

  .card button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link.collapsed {
    font-size: 16px;
  }

  .available-boxes-main {
    width: 100%;
  }

  .pricing-area.profile-main {
    padding-bottom: 20px;
  }

  .under-appointment {
    padding: 65px 0 0 0;
    overflow-x: hidden;
  }

  .appointment-content-text {
    width: 100%;
  }

  .appointment-area {
    justify-content: center;
  }

  .details-user {
    margin-left: 0;
    text-align: center;
    margin-top: 10px;
  }

  #counter {
    right: 32%;
  }
}

@media screen and (max-width: 420px) {
  .chat-agreement-area {
    margin: 6px 0 !important;
  }
  div#otp div {
    flex-wrap: unset;
}

  .doctor-side .icons-left {
    flex-direction: column;
    align-items: flex-end;
  }

  .icons-left .d-flex {
    margin-bottom: 6px;
  }

  .inner-dashboard-section .card-shadow {
    width: 100%;
  }

  .timing-zone .css-160unip>.MuiTextField-root {
    min-width: 149px !important;
  }

  .timing-zone .css-160unip>.MuiTextField-root {
    min-width: 129px;
  }

  .prompt-box-modal .modal-content {
    padding: 0;
  }

  .prompt-box-modal .modal-dialog {
    max-width: 354px;
  }

  .modal-header.heathy-header {
    padding: 10px 0 22px 0;
  }

  .inner-health h6 {
    font-size: 14px;
  }

  .sub-para {
    padding: 0 12px;
  }

  .overflow {
    padding: 0 0;
  }

  .left-chat-boxes.y.mobile-view-bookmark {
    min-height: 100% !important;
  }

  .chat-history-header span {
    font-size: 17px;
  }

  .select-slot {
    margin-top: 3px;
    font-size: 14px;
  }

  .modal-area-box textarea#formFile,
  .left-chat-box p,
  .reason-boxes .form-control,
  .tags-box-main .tags,
  .btn-close:after {
    font-size: 13px;
  }

  .right-chat-box p {
    margin-bottom: 7px;
  }

  .content {
    padding: 0 0;
  }

  .features-section.common-padding .atorny-card {
    height: 320px;
  }

  .modal-title.h4 {
    font-size: 16px;
  }

  .input-box .form-control {
    font-size: 12px;
  }

  .recommend-box span {
    padding: 3px 6px;
  }

  .modal-title.h4 {
    font-size: 18px;
  }

  .content__container__list__item {
    font-size: 24px;
  }

  .hero-banner video {
    height: 370px;
  }

  footer {
    margin-top: 0px !important;
  }

  .mid-chat-box {
    width: 94%;
  }

  .atorny-card {
    height: 259px;
  }

  .health-right-box h5 p {
    font-size: 13px;
  }

  .feature-box {
    height: 330px;
  }

  .available-boxes-main button img {
    width: 18px;
    margin-left: 14px !important;
  }

  .availabilty-box {
    padding: 8px;
  }

  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 12px !important;
  }

  .dosage-area select#formBasicEmail {
    width: 24% !important;
  }



  .timing-zone {
    width: 47%;
  }

  .complete-text.btn.btn-unset {
    padding: 5px 8px;
    font-size: 10px;
  }

  .appointment-content-text {
    width: 80%;
  }

  .appointment-area {
    justify-content: center;
  }

  .modal-dialog .right-chat-spacing {
    padding: 0 10px 10px !important;
  }

  .right-chat-box-dropdown {
    margin: 0;
  }

  .modal-area-box .modal-header {
    padding: 0;
  }

  .btn-close:after {
    top: 8px;
  }

  /* 
  .right-chat-box-dropdown br {
    display: none;
  } */

  .cancel-area-box h5 {
    font-size: 18px !important;
  }

  .call-to-action-left h4 {
    font-size: 20px;
  }

  .call-to-action .container {
    padding: 25px;
  }

  .card button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link.collapsed {
    font-size: 14px;
  }

  .login-form-box button {
    padding: 8px 9px;
  }

  .NFTICALLYAdvantageContent .text-light,
  .sub-heading {
    font-size: 20px;
  }

  .pricing-top-heading-area h4 {
    font-size: 30px;
    margin-bottom: 0;
  }

  .payment-add-card h4 {
    font-size: 17px;
  }

  .payment-detail-area {
    padding: 20px 20px;
  }

  .add-card button {
    background: #fff0a3 !important;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    padding: 7px 8px;
    border: none !important;
    white-space: nowrap;
  }

  .login-right h6 {
    font-size: 20px;
  }

  .modal-dialog {
    width: 100%;
  }

  .right-chat-box-dropdown .dropdown button {
    font-size: 13px;
  }

  .modal-content {
    padding: 15px;
  }

  .mid-chat-box {
    background: #fff;
  }

  .inner-chatbot {
    background: #fff;
  }

  .google-login-btn button {
    font-size: 14px;
    border: 1px solid #000 !important;
  }

  .left-chat-boxes,
  .right-chat-boxes {
    background: #fff;
  }

  /* .inner-chatbot {
    padding: 7px 0;
  } */
  .add-chat-btn {
    margin: 12px 0;
  }

  .cancel-btn {
    width: 100% !important;
    padding: 8px !important;
    border: 0 !important;
  }

  .animated-btn {
    font-size: 12px;
  }
}

@media screen and (max-width: 350px) {
  .features-section.common-padding .atorny-card {
    height: 357px;
  }

  .timing-zone .css-160unip>.MuiTextField-root {
    min-width: 110px !important;
  }

  .chatbox-body {
    height: 282px;
  }

  .chat-animated-button .animated-btn {
    padding: 2px 5px;
  }
}

@media screen and (max-width: 320px) {
  .google-login-btn button {
    padding: 10px 0;
  }

  .features-section.common-padding .atorny-card {
    height: 360px;
  }

  .login-right {
    min-width: 100%;
  }
}