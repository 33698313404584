* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
}

html,
body,
address,
blockquote,
div,
dl,
form,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
table,
ul,
dd,
dt,
li,
tbody,
td,
tfoot,
th,
thead,
tr,
button,
del,
ins,
map,
object,
a,
abbr,
acronym,
b,
bdo,
big,
br,
cite,
code,
dfn,
em,
i,
img,
kbd,
q,
samp,
small,
span,
strong,
sub,
sup,
tt,
var,
legend,
fieldset,
p,
aside,
header,
footer,
section,
article {
  border: medium none;
  margin: 0;
  padding: 0;
}

:focus {
  outline: none !important;
}

img,
fieldset {
  border: 0 none;
}

a {
  border: none;
  outline: none;
  text-decoration: none;
}

input[type="submit"] {
  -webkit-appearance: none;
}

img {
  border: none;
  height: auto;
  max-width: 100%;
  outline: none;
  vertical-align: middle;
}

.availabilty-box {
  /* display: flex; */
  /* align-items: center;
  justify-content: space-between; */
  background: #7269ef17;
  border-radius: 12px;
  padding: 17px;
  margin-bottom: 13px;
  box-shadow: 0px 4px 31px 0px rgb(0 0 0 / 6%);
  border: 1px solid #7269ef59;
}

.available-boxes-main button {
  background: transparent;
}

.availability-box {
  padding: 48px 0;
}

.add-available-box button {
  background: transparent !important;
  border: none !important;
  cursor: pointer !important;
}

.add-available-box .dropdown-top .btn-check:checked+.btn,
.add-available-box .btn.active,
.add-available-box .btn.show,
.add-available-box .btn:first-child:active,
.add-available-box :not(.btn-check)+.btn:active {
  background: transparent !important;
}

.availability-box input[type="checkbox"],
input[type="radio"] {
  vertical-align: baseline;
  accent-color: #7269ef;
}

.css-1ed1o9w-MuiList-root-MuiMultiSectionDigitalClockSection-root:after {
  height: unset;
}

.availabilty-box h5 {
  font-weight: 600;
  font-size: 16px;
}

.timing-zone {
  margin-bottom: 7px;
}

a.btn-disabled {
  background: #808080a8 !important;
  cursor: not-allowed;
}

.join-button {
  background: #7269ef;
  padding: 8px;
  color: #fff;
  border-radius: 22px;
  padding: 6px 33px;
}

.try-ti-butons {
  padding: 13px;
  background: #7269ef;
  color: #000;
  font-size: 15px;
  display: inline-block;
  margin: 0 0 0 0;
  padding: 14px 15px;
  border-radius: 0 22px;
}

.availabilty-btn .join-button:hover {
  color: #fff;
}